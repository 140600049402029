<template>
  <div class="page-anim1">
    <div class="bg-holder">
      <img src="../assets/images/login.png" class="bg" />
    </div>
    <div class="container">
      <div class="login-holder">
        <img
          class="logo page-anim2"
          src="../assets/images/logo.png"
          alt="cipatex"
        />
        <div>
          <button
            @click="$navigate('/home')"
            class="btn desktop12 tablet12 mobile12 bg-secondary register page-anim3 mb-1"
          >
            <span v-translate>Entrar como visitante</span>
          </button>
          <span class="visitor-description">
            {{
              t(
                'Para ter acesso a 100% das funcionalidades do catálogo como "favoritos", "PDFs personalizados" é necessário estar cadastrado.'
              )
            }}
          </span>
        </div>

        <h1 class="title mt-1 page-anim2" style="margin-bottom:10px;">
          {{ t("Já sou cadastrado") }}
        </h1>
        <div
          @keypress.enter="login"
          class="input-item page-anim2"
          style="margin-top:0;"
        >
          <span>E-mail</span>
          <input type="email" v-model="loginForm.email" />
        </div>
        <div @keypress.enter="login" class="input-item page-anim2">
          <span v-translate>Senha</span>
          <input type="password" v-model="loginForm.password" />
        </div>

        <transition enter-active-class="animated fadeIn">
          <p v-show="warningMessage != ''" class="warning">
            {{ warningMessage }}
          </p>
        </transition>

        <button
          @click="login"
          class="btn desktop12 tablet12 mobile12 mx-auto page-anim3 login"
        >
          <span v-show="!loading" v-translate>Entrar</span>
          <div v-show="loading" class="loading-spinner"></div>
        </button>

        <h1 class="title new-user mt-2 page-anim3">
          {{ t("Ainda não é cadastrado?") }}
        </h1>
        <button
          @click="$router.push('/cadastro')"
          class="btn desktop12 tablet12 mobile12 bg-secondary register page-anim3"
        >
          <span v-translate>Cadastrar</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import validators from "../util/validators";
import authentication from "../util/authentication";

export default {
  name: "Login",
  data() {
    return {
      warningMessage: "",
      loading: false,
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      if (!validators.validateEmail(this.loginForm.email)) {
        this.warningMessage =
          "Por favor, verifique se o e-mail digitado está correto.";
        return;
      }
      if (this.loginForm.password == "") {
        this.warningMessage = "Por favor, informe sua senha";
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      authentication
        .login(this.loginForm)
        .then((response) => {
          if (response) {
            this.$navigate("/home");
          } else {
            this.warningMessage = "Usuário e/ou senha incorreto(s)";
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Algo deu errado. Por favor, tente novamente em alguns instantes.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.page-anim1 {
  background-color: #000;
}

.visitor-description {
  opacity: 0.8;
  color: #fff;
  margin-bottom: 10px;
}

img.bg {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
}

img.logo {
  position: relative;
  display: block;
  max-width: 160px;
  margin-bottom: 10px;
}

.login-holder {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 0;
}

.btn.register {
  margin-top: 10px;
}

h1.title {
  font-family: daxRegular;
  font-size: 1.4em;
  color: #fff;
  font-weight: lighter;
}

.input-item span {
  color: #fff;
}

.guest-link {
  position: relative;
  color: var(--secondary);
  text-decoration: underline;
}

input {
  color: #fff;
}

@media screen and (min-width: 1160px) {
  .page-anim1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 3rem;
    justify-content: flex-start;
    position: relative;
    width: 100vw;
    align-items: center;
    background-color: var(--primary);
  }
  .container {
    margin: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login-holder {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 45px 15px;
  }
  .bg-holder {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  img.bg {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 50%;
    opacity: 1;
    filter: brightness(0.7);
    transform: translateX(-50%);
    border-radius: 0;
  }
  button.btn.login {
    background-color: var(--dark-blue);
  }
}
</style>
