<template>
  <div class="page-anim1">
    <div class="container">
      <div class="img-container">
        <img class="bg" src="../assets/images/idioma.png" />
        <div class="logo-holder">
          <img src="../assets/images/logo.png" alt="logo" />
        </div>
      </div>

      <div
        @click="setLanguage('br')"
        class="language-item"
        style="margin-top:46px;"
      >
        <img src="../assets/images/flag-br.png" />
        <p>Português</p>
      </div>
      <div @click="setLanguage('es')" class="language-item">
        <img src="../assets/images/flag-es.png" />
        <p>Español</p>
      </div>
      <div
        @click="setLanguage('en')"
        class="language-item"
        style="margin-bottom:35px;"
      >
        <img src="../assets/images/flag-en.png" />
        <p>English</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      warningMessage: "",
    };
  },
  methods: {
    setLanguage(language) {
      localStorage.language = language;
      this.$translate.setLang(language);
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.img-container {
  position: relative;
  width: 100%;
  max-width: 480px;
  padding-bottom: 103.626%;
  margin: 4.5vw auto 0 auto;
}

.img-container img.bg {
  position: absolute;
  height: 100%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate3d(-50%, -50%, 0);
}

.img-container .logo-holder {
  position: absolute;
  width: 70%;
  max-width: 420px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -25px;
  padding: 15px 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}

.img-container .logo-holder img {
  max-width: 80%;
}

.language-item {
  position: relative;
  width: 100%;
  max-width: 194px;
  padding: 15px 23px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.language-item img {
  position: relative;
  max-height: 24px;
}

.language-item p {
  font-size: 1.1em;
  color: var(--primary);
  margin-left: 14px;
}

@media screen and (min-width: 1160px) {
  .img-container {
    width: 450px;
    padding-bottom: 466px;
    margin: 35px auto;
  }
}
</style>
