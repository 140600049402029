<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ t("COLEÇÕES") }}
        </h1>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div v-show="!loading" class="collection-holder">
          <div v-for="col in collections" :key="col.id" class="collection">
            <div
              class="img-holder pointer"
              @click="$navigate(`/colecoes/${col.id}`)"
            >
              <img :src="col.image" alt="" />
            </div>
            <div class="content">
              <h1 class="pointer" @click="$navigate(`/colecoes/${col.id}`)">
                {{ col[currentLanguage] }}
              </h1>
              <span class="pointer" @click="$navigate(`/colecoes/${col.id}`)"
                >{{ t("Encerramento:") }}
                {{ col.endDate | moment("DD/MM/YYYY") }}</span
              >
              <div class="content-holder">
                <div
                  class="market pointer"
                  @click="$navigate(`/colecoes/${col.id}`)"
                >
                  <p><b v-translate>Mercado:</b> {{ col.categories }}</p>
                </div>
                <div class="link-hotsite">
                  <a
                    v-show="col.hotsite"
                    :href="col.hotsite"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <b v-show="col.hotsite" v-translate>Saiba mais</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
// import validators from "../util/validators";
// import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default {
  name: "Collection",
  components: {
    Header,
    Navbar,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      collections: [],
    };
  },
  created() {
    http
      .get("/collection/list")
      .then((response) => {
        this.collections = response.data.list;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
};
</script>
<style lang="scss" scoped>
.collection-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.collection {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img-holder {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    background-color: #f3f3f3;
    border-radius: 12px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;

    img {
      position: absolute;
      max-width: 90%;
      top: 50%;
      left: 50%;
      margin: 0 auto;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .content {
    position: relative;
    width: calc(100% - 150px);
    height: 100%;
    padding: 15px;
    background-color: #f3f3f3;
    border-radius: 0 12px 12px 0;
    z-index: 1;

    h1 {
      font-size: 1.5em;
    }
  }

  .content-holder {
    position: relative;
    bottom: 0;
    margin-top: 10px;

    .market {
      margin-bottom: 6px;
    }

    .link-hotsite {
      display: flex;
      justify-content: flex-start;
      width: 202.188px;
      height: 32px;

      b {
        position: relative;
        color: #1770c4;
      }
    }
  }
}

@media screen and (min-width: 1160px) {
  .collection-holder {
    grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  }
  .collection {
    width: initial;
  }
}
</style>
