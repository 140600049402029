<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="opened" @click="closeIt" class="bg-cover"></div>
    </transition>

    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <div v-show="opened" class="holder">
        <font-awesome-icon
          @click="closeIt"
          class="close pointer"
          :icon="['fas', 'times']"
        />
        <ul>
          <li @click="showFilter = !showFilter" class="pointer">
            <a>{{ t("FILTRO TÉCNICO") }}</a>
          </li>
          <li>
            <a>{{ t("IDIOMAS") }}</a>
            <div class="flags">
              <img
                class="pointer"
                :class="{ active: language === 'br' || language === '' }"
                @click="setLanguage('br', true)"
                src="../assets/images/flag-br.png"
                alt="Br Flag"
              />
              <img
                class="pointer"
                :class="{ active: language === 'es' }"
                @click="setLanguage('es', true)"
                src="../assets/images/flag-es.png"
                alt="Es Flag"
              />
              <img
                class="pointer"
                :class="{ active: language === 'en' }"
                @click="setLanguage('en', true)"
                src="../assets/images/flag-en.png"
                alt="Uk Flag"
              />
            </div>
          </li>
          <li>
            <router-link to="/termos">{{ t("TERMOS DE USO") }}</router-link>
          </li>

          <li v-show="userLoggedIn">
            <a class="pointer" @click="logout">{{ t("SAIR") }}</a>
          </li>

          <li v-show="!userLoggedIn">
            <router-link class="pointer" to="/login">{{
              t("ENTRAR/CADASTRAR")
            }}</router-link>
          </li>
        </ul>

        <div class="calc">
          <div class="header">
            <div>
              <font-awesome-icon :icon="['fas', 'calculator']" />
            </div>
            <div>
              <h2 v-translate>Calculadora</h2>
              <p v-translate>Calcule o metro linear</p>
            </div>
          </div>
          <div class="item">
            <p v-translate>Largura (m):</p>
            <input
              v-model="width"
              type="number"
              @focus="focusWidthOrLenght()"
            />
          </div>
          <div class="item">
            <p v-translate>Comprimento (m):</p>
            <input
              v-model="length"
              type="number"
              @focus="focusWidthOrLenght()"
            />
          </div>
          <div class="divider">
            <span v-translate>ou</span>
          </div>
          <div class="item mt-1">
            <p v-translate>Preencha o nº de m²:</p>
            <input v-model="square" type="number" @focus="focusSquare()" />
          </div>
          <div class="divider"></div>
          <p class="mt-1" v-translate>Número em metros lineares</p>
          <h1 class="result">{{ linearMeters }}</h1>
        </div>

        <div class="media">
          <a
            class="desktop-hidden"
            href="https://cipatex.com.br/"
            rel="noreferrer"
            target="_blank"
          >
            SITE CIPATEX
          </a>
          <a
            href="https://www.instagram.com/cipatex/"
            rel="noreferrer"
            target="_blank"
          >
            <font-awesome-icon
              class="close pointer"
              :icon="['fab', 'instagram']"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/cipatex/"
            rel="noreferrer"
            target="_blank"
          >
            <font-awesome-icon
              class="close pointer"
              :icon="['fab', 'linkedin']"
            />
          </a>
          <a
            href="https://www.facebook.com/Cipatex/ "
            rel="noreferrer"
            target="_blank"
          >
            <font-awesome-icon
              class="close pointer"
              :icon="['fab', 'facebook']"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCvkUKYIPF1pD_zpMPRKnNYg "
            rel="noreferrer"
            target="_blank"
          >
            <font-awesome-icon
              class="close pointer"
              :icon="['fab', 'youtube']"
            />
          </a>
        </div>
      </div>
    </transition>

    <FilterWidget :opened="showFilter" @closeFilter="closeFilterComponent" />
  </div>
</template>

<script>
import authentication from "../util/authentication";
import FilterWidget from "../components/Filter";
export default {
  name: "Menu",
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterWidget,
  },
  data() {
    return {
      language: localStorage.language || "br",
      showFilter: false,
      width: 0,
      length: 0,
      square: 0,
    };
  },
  computed: {
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
    pageName() {
      return this.$route.name;
    },
    linearMeters() {
      if (this.square > 0) {
        return (this.square / 1.4).toFixed(2);
      } else {
        if (this.width > 0 && this.length > 0) {
          return ((this.width * this.length) / 1.4).toFixed(2);
        }
      }
      return "";
    },
  },
  methods: {
    focusWidthOrLenght() {
      this.square = 0;
    },
    focusSquare() {
      this.width = 0;
      this.length = 0;
    },
    closeFilterComponent() {
      this.showFilter = false;
    },
    setLanguage(language, reloadPage) {
      if (this.language === language) return;
      this.language = language;
      localStorage.language = language;

      if (reloadPage) this.$router.push({ query: { lang: language } });
    },
    closeIt() {
      this.$emit("closeMenu");
    },
    logout() {
      authentication.logout();
    },
  },
  watch: {
    $route: {
      handler: function(to, from) {
        this.setLanguage(to.query.lang, false);
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.holder {
  position: fixed;
  width: 80vw;
  height: 100vh;
  max-width: 480px;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  background-color: #fff;
  z-index: 10000;
  margin: 0;
  top: 0;
  right: 0;
  overflow: auto;
}

.close {
  position: relative;
  display: block;
  margin-left: auto;
  font-size: 2em;
}

.holder ul {
  margin-top: 15px;
}

.holder ul li {
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000015;
}

.flags {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.flags img {
  max-height: 52%;
  margin-left: 10px;
  filter: saturate(0.25);
  opacity: 0.35;
  transition: opacity 0.25s ease;
}

.flags img.active {
  filter: saturate(1);
  opacity: 1;
}

.calc {
  position: relative;
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  background-color: var(--primary);
  border-radius: 10px;
}

.calc .header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff2f;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.calc .header svg {
  font-size: 2.6em;
  color: #fff;
  margin-right: 14px;
}

.calc h2,
.calc p {
  font-size: 1.6em;
  color: #fff;
}

.calc p {
  font-size: 1.1em;
}

.item {
  position: relative;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item input {
  height: 100%;
  padding-left: 8px;
  background-color: #ffffff44;
  color: #fff;
  max-width: 36%;
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 18px 0 0 0;
  background-color: #ffffff2f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider span {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 0 8px;
  background-color: var(--primary);
  top: -2px;
}

h1.result {
  font-size: 2.6em;
  color: #fff;
}

.media {
  position: absolute;
  width: calc(100% - 40px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.media svg {
  font-size: 1.4em;
  margin-left: 15px;
}

.media a:nth-child(1) {
  position: absolute;
  left: 0;
}

@media screen and (min-width: 1160px) {
}
</style>
