<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import languages from "@/util/languages";
export default {
  name: "App",
  locales: languages,
};
</script>
<style lang="scss">
:root {
  --primary: #17489a;
  --secondary: #f1b016;
  --dark-blue: #0d1e2a;
  --gray1: #f7f7f7;
  --silver1: #eaeaea;
  --dark-primary: #1e2124;
  --dark1: #000;
  --dark2: #1a1a1a;
  --dark3: #202020;
  --dark4: #333;
  --dark5: #484848;
  --red1: #c7244a;
  --green1: #3caf17;
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.desaturate {
  filter: saturate(0) !important;
}

.bg-red {
  background-color: var(--red1) !important;
}

.bg-green {
  background-color: var(--green1) !important;
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  font-family: daxRegular;
  color: var(--dark5);
  box-sizing: border-box;
}

@font-face {
  font-family: "fontRegular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontLight";
  src: url("./assets/fonts/Roboto-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontMedium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBlack";
  src: url("./assets/fonts/Roboto-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "daxRegular";
  src: url("./assets/fonts/DaxRegular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "daxBold";
  src: url("./assets/fonts/DaxBold.ttf");
  font-display: swap;
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

.clearfix {
  clear: both !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-blue {
  color: var(--blue) !important;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary) !important;
}

.text-desc {
  font-family: fontRegular;
  font-size: 1.3em;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.uppercase {
  text-transform: uppercase;
}

ul {
  list-style: none;
}

.mt-header {
  margin-top: 77px;
}

.page {
  padding-bottom: 100px;
}

.page-title {
  font-family: daxBold;
  font-size: 2em;
  color: var(--primary);
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 10000;
}

section.content {
  position: relative;
  width: 100vw;
  background-color: var(--dark-blue);
  padding: 0 !important;
  float: left;
}

.content-inner {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 134px);
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  float: left;
  padding-bottom: 100px;
}

.all {
  background-color: var(--dark-blue);
}

select {
  background: transparent;
}

.input-item {
  position: relative;
  width: 100%;
  margin: 25px 0 0 0;
}

.flex-between .input-item {
  width: 48%;
}

.input-item span {
  font-family: fontLight;
  position: relative;
  display: block;
  font-size: 1.1em;
  color: #555;
}

.input-item span.blue {
  color: var(--blue);
}

.input-item input,
.input-item select {
  position: relative;
  font-family: fontRegular;
  padding-left: 12px;
  font-size: 1.3em;
  color: var(--dark4);
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #999;
  background-color: transparent;
}

.input-item input::placeholder {
  font-family: fontLight;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .card {
  position: relative;
  width: 90%;
  max-width: 460px;
  padding: 22px 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.25);
}

.modal .card h1 {
  font-family: fontLight;
  font-size: 1.6em;
  text-align: center;
}

.card .input-item span {
  font-size: 1em;
}

.warning {
  font-family: fontLight;
  position: relative;
  text-align: center;
  font-size: 1.2em;
  margin-top: 36px;
  color: rgb(255, 208, 80);
  text-shadow: 1px 1px #000;
}

.warning.red {
  color: #f00;
  text-shadow: none;
}

.warning.success {
  color: rgb(21, 165, 45);
  text-shadow: none;
}

.pointer {
  cursor: pointer;
}

.loading1 {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: -40px;
}
.loading1 div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary);
  animation: loading1 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading1 div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading1 div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading1 div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading1 {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.arrow-up {
  position: absolute;
  top: -25px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 26px solid var(--primary);
}

.arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: -25px;
  margin: 0 auto;
  border-top: 26px solid transparent;
  border-right: 26px solid var(--primary);
  border-bottom: 26px solid transparent;
  transform: translateY(-50%);
}

.section-title {
  font-family: fontLight;
  position: relative;
  color: var(--dark-blue);
  font-size: 1.6em;
}

.btn {
  position: relative;
  padding: 16px 20px;
  border-radius: 6px;
  margin-top: 25px;
  background-color: var(--primary);
  box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.15);
  transition: transform 0.25s ease;
  cursor: pointer;
}

.btn span {
  font-family: fontRegular;
  font-size: 1.2em;
  color: #fff;
}

.btn:hover {
  transform: translateY(-3px);
}

.app-btn {
  position: relative;
  padding: 16px 32px;
  border-radius: 7px;
  background-color: var(--primary);
  margin: 25px auto 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  transition: transform 0.25s ease, width 0.3s ease;
}

.app-btn:hover {
  transform: translateY(-3px);
}

.app-btn.secondary {
  background-color: var(--secondary);
}

.app-btn span {
  font-family: fontRegular;
  font-size: 1.2em;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
}

.app-btn.border {
  border: 1px solid var(--primary);
  background-color: transparent;
}

.app-btn.border span {
  color: var(--primary);
}

.app-btn.icon span {
  margin-left: 12px;
}

.app-btn.icon svg {
  font-size: 1.3em;
}

.back-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.back-button svg {
  font-size: 1.3em;
}

.screen-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000000cf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  p {
    color: #fff;
    margin-top: 20px;
    letter-spacing: 1px;
  }
}

.products-holder {
  position: relative;
  width: 100%;
  margin: 0 auto 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 15px;
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  .products-holder {
    grid-template-columns: repeat(auto-fit, minmax(160px, 0.5fr));
    gap: 20px;
  }
}

@media screen and (min-width: 1160px) {
  .products-holder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 0.25fr));
    gap: 25px;
  }
}

.spotlight-image-holder {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56%;
  margin: 0 auto 25px auto;
  border-radius: 10px;
  overflow: hidden;
}

.spotlight-image-holder img {
  position: absolute;
  max-width: 110%;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.card {
  position: relative;
  overflow: hidden;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }

  &.border {
    border: 1px solid #ccc;
    border-radius: 8px;

    img {
      max-width: 90%;
    }
  }
}

.loading-spinner {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading-spinner.primary {
  border-color: var(--primary);
  border-right-color: transparent;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading2 {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading2.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.loading2.white {
  border: 2px solid #fff;
  border-right-color: transparent;
}

.full-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-container {
  z-index: 100000 !important;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-anim1 {
  animation-name: anim1;
  animation-duration: 0.3s;
}

.page-anim2 {
  animation-name: anim2;
  animation-duration: 0.3s;
}

.page-anim3 {
  animation-name: anim3;
  animation-duration: 0.3s;
}

@keyframes anim1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim2 {
  from {
    opacity: 0;
    transform: translateX(17vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes anim3 {
  from {
    opacity: 0;
    transform: translateY(25vw);
  }
  to {
    opacity: 1;
    transform: translateY(0vw);
  }
}
</style>
