<template>
  <div class="prod pointer">
    <img @click="$navigate(`/produto-detalhes/${id}`)" :src="image" />
    <p>{{ name }}</p>
    <p v-show="code">{{ `COD: ${code}` }}</p>

    <div v-show="userIsLoggedIn">
      <div
        v-show="showFavoriteButton && !showRemoveButton"
        @click="showFavoriteOptions = true"
        class="favorite"
      >
        <font-awesome-icon :icon="['far', 'bookmark']" />
      </div>

      <div
        v-show="showRemoveButton"
        @click="removeFromFavorites"
        class="favorite"
      >
        <font-awesome-icon :icon="['fas', 'bookmark']" />
      </div>
    </div>

    <FavoriteListsOptions
      :id="id"
      :image="image"
      :showComponent="showFavoriteOptions"
      @closeComponent="closeFavoriteOptions"
    />
  </div>
</template>
<script>
import authentication from "../util/authentication";
import FavoriteListsOptions from "./FavoriteListsOptions";
export default {
  name: "ProductItem",
  components: {
    FavoriteListsOptions,
  },
  props: {
    id: {
      type: String,
      default: "--",
    },
    name: {
      type: String,
      default: "--",
    },
    code: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
    showFavoriteButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userIsLoggedIn() {
      return authentication.isLoggedIn();
    },
  },
  data() {
    return {
      loading: false,
      showFavoriteOptions: false,
    };
  },
  methods: {
    closeFavoriteOptions() {
      this.showFavoriteOptions = false;
    },
    removeFromFavorites() {
      if (this.loading) return;
      this.$swal
        .fire({
          type: "error",
          title: this.$translate.locale["Remoção dos favoritos"],
          text: this.$translate.locale[
            `Tem certeza que deseja remover este produto dos favoritos?`
          ],
          confirmButtonText: this.$translate.locale["Remover"],
          confirmButtonColor: "var(--red1)",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.$emit("removeProduct", this.id);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.prod {
  position: relative;
  overflow: hidden;
  min-width: 120px;
  min-height: 100px;
  p {
    text-transform: uppercase;
  }
}

.prod img {
  position: relative;
  width: 100%;
}

.favorite {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: grid;
  place-items: center;
  svg {
    color: #fff;
  }
}
</style>
