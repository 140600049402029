<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          Resultado dos filtros
        </h1>

        <div class="products-holder">
          <ProductItem
            v-for="(prod, index) in products"
            :id="prod.id"
            :key="index"
            :image="prod.coverImage"
            :name="prod.namePt"
            :showRemoveButton="false"
            @favorite="checkFavorites"
          />
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import favorites from "../util/favorites";

export default {
  name: "Filters",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      warningMessage: "",
      loading: false,
      products: [],
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    checkFavorites() {
      let allFavorites = favorites.list();
      if (!allFavorites) return;
      this.products.map((fav) => {
        if (allFavorites.includes(fav.id)) {
          fav.isFavorite = true;
        } else {
          fav.isFavorite = false;
        }
      });
      this.$forceUpdate();
    },
    getProducts() {
      this.loading = true;
      http
        .get(
          `product/list?orderBy=namePt-&segmentId=${this.$route.query.segmentId}&destinationId=${this.$route.query.destinationId}&colorId=${this.$route.query.colorId}&collectionId=${this.$route.query.collectionId}&take=-1`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = response.data.list;
            this.checkFavorites();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
