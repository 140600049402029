<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ t("Minhas coleções") }}
        </h1>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <transition enter-active-class="animated fadeInLeft">
          <div v-show="!loading" class="lists-holder">
            <div class="item" v-for="list in userList" :key="list.id">
              <router-link :to="`/minhas-colecoes/${list.id}`" class="top">
                <p>{{ list.name }}</p>
                <span>{{ `(${list.products.length})` }}</span>
              </router-link>
              <div class="content">
                <div class="holder">
                  <div @click="editListName(list)" class="opt pointer">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </div>
                  <div @click="deleteList(list)" class="opt pointer">
                    <font-awesome-icon
                      class="delete"
                      :icon="['fas', 'trash']"
                    />
                  </div>
                </div>
                <router-link :to="`/minhas-colecoes/${list.id}`" class="opt">
                  <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']" />
                </router-link>
              </div>
            </div>
          </div>
        </transition>

        <div class="flex-center mt-2 pointer">
          <div @click="showNewCollectionWindow = true" class="new-collection">
            <font-awesome-icon :icon="['fas', 'plus-circle']" />
            <p v-translate>nova coleção</p>
          </div>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        @click.self="closeModal"
        v-show="showNewCollectionWindow"
        class="screen-loading"
      >
        <div class="window">
          <div class="input-item page-anim2">
            <span v-translate>{{ t("Nome da coleção *") }}</span>
            <input
              @keypress.enter="createCollection"
              type="text"
              v-model="newCollectionName"
            />
          </div>
          <div class="flex-between">
            <div @click="closeModal" class="app-btn border">
              <span v-translate>Cancelar</span>
            </div>
            <div
              v-show="!selectedList.id"
              @click="createCollection"
              class="app-btn"
            >
              <div v-show="creatingCollection" class="loading2 white"></div>
              <span v-show="!creatingCollection" v-translate>Criar</span>
            </div>
            <div
              v-show="selectedList.id"
              @click="editCollectionName"
              class="app-btn"
            >
              <div v-show="creatingCollection" class="loading2 white"></div>
              <span v-show="!creatingCollection" v-translate>Salvar</span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default {
  name: "UserFavorite",
  components: {
    Header,
    Navbar,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      userList: [],
      selectedList: { id: "" },
      showNewCollectionWindow: false,
      newCollectionName: "",
      creatingCollection: false,
    };
  },
  created() {
    this.getLists();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    deleteList(list) {
      this.$swal
        .fire({
          type: "error",
          title: this.$translate.locale["Excluir coleção"],
          text: this.$translate.locale[
            `Tem certeza que deseja excluir esta coleção?`
          ],
          confirmButtonText: this.$translate.locale["Remover"],
          confirmButtonColor: "var(--red1)",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            http
              .delete(`userList?id=${list.id}`, authentication.getHeaders())
              .then(() => {
                this.userList = this.userList.filter(
                  (item) => item.id !== list.id
                );
                this.$toast({
                  type: "success",
                  text: this.$translate.locale["Coleção excluída"],
                });
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                this.closeModal();
              });
          }
        });
    },
    editListName(list) {
      this.selectedList = list;
      this.showNewCollectionWindow = true;
      this.newCollectionName = list.name;
    },
    closeModal() {
      this.showNewCollectionWindow = false;
      this.selectedList = { id: "" };
      this.newCollectionName = "";
      this.creatingCollection = false;
    },
    editCollectionName() {
      if (!this.newCollectionName || !this.selectedList.id) return;
      this.creatingCollection = true;
      let editedList = this.selectedList;
      editedList.name = this.newCollectionName;

      http
        .put("userList", editedList, authentication.getHeaders())
        .then((response) => {
          console.log(response.data);
          this.$swal({
            toast: true,
            type: "success",
            title: "Nome da coleção alterado!",
            position: "top-right",
            showConfirmButton: false,
            timer: 3200,
          });
          this.selectedList.name = response.data.name;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.closeModal();
        });
    },
    createCollection() {
      if (!this.newCollectionName) return;
      this.creatingCollection = true;

      let newList = { name: this.newCollectionName };

      http
        .post("/userList", newList, authentication.getHeaders())
        .then((response) => {
          this.userList.unshift(response.data);
          this.$toast({ text: this.$translate.locale["Coleção criada"] });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.closeModal();
        });
    },
    getLists() {
      http
        .get(`userList/list?orderBy=timestamp`, authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.userList = response.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.lists-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  .item {
    position: relative;
    max-width: 520px;
    border: 1px solid var(--primary);
    padding: 1em 1em 0 1em;
    border-radius: 1em;
    .top {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      p {
        font-size: 1.2em;
        color: var(--primary);
      }
      span {
        font-size: 1.1em;
        color: #999;
      }
    }
    .content {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .holder {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .opt {
          margin-right: 15px;
        }
      }
      .opt {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        svg {
          color: var(--primary);
          font-size: 1.2em;
          &.delete {
            color: var(--red1);
          }
        }
      }
    }
  }
}

.new-collection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  svg {
    font-size: 1.4em;
    color: var(--dark-primary);
  }
  p {
    font-size: 1.15em;
    margin-left: 15px;
    color: var(--dark-primary);
  }
}

.window {
  position: relative;
  width: 90%;
  max-width: 540px;
  background-color: #fff;
  border-radius: 1.5em;
  padding: 1.5em;
}
</style>
