<template>
	<div class="page">
		<Header :showBackButton="true" :showLogo="false" />
		<div class="page-anim1 mt-header">
			<div class="container">
				<h1
					class="page-title page-anim2 mb-1 desktop-hidden"
					style="margin-bottom:10px;"
				>
					{{ segment[currentLanguage] }}
				</h1>
				<div class="spotlight-image-holder desktop-hidden">
					<img :src="segment.image" :alt="segment[currentLanguage]" />
				</div>

				<transition enter-active-class="animated fadeIn">
					<div
						v-show="segment.image"
						class="page-title-image tablet-hidden mobile-hidden"
						:style="`background-image:url(${segment.image})`"
					>
						<h1 class="page-title">{{ segment[currentLanguage] }}</h1>
					</div>
				</transition>

				<div v-show="loading" class="flex-center mt-3">
					<div class="loading2"></div>
				</div>

				<div v-show="!loading" class="products-holder">
					<router-link
						v-for="cat in categories"
						:key="cat.id"
						:to="`/categoria/${cat.id}`"
						class="card border"
					>
						<img :src="cat.image" :alt="cat[currentLanguage]" />
					</router-link>
				</div>
			</div>
		</div>

		<Navbar />
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import Header from "../components/Header";
	import Navbar from "../components/Navbar";

	export default {
		name: "Segment",
		components: {
			Header,
			Navbar,
		},
		data() {
			return {
				warningMessage: "",
				loading: true,
				segment: {},
				categories: [],
			};
		},
		created() {
			this.getSegment();
		},
		computed: {
			currentLanguage() {
				if (localStorage.language == "es") {
					return "nameEs";
				} else if (localStorage.language == "en") {
					return "nameEn";
				}
				return "namePt";
			},
		},
		methods: {
			getSegment() {
				http
					.get(
						`category?id=${this.$route.params.id}`,
						authentication.getHeaders()
					)
					.then((response) => {
						if (response.status === 200) {
							this.segment = response.data;
						}
					})
					.catch((err) => {
						console.log(err);
					});

				http
					.get(
						`category/List?parentId=${this.$route.params.id}&take=-1`,
						authentication.getHeaders()
					)
					.then((response) => {
						if (response.status === 200) {
							this.categories = response.data.list;
						}
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.page-title-image {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		border-radius: 10px;
		margin-bottom: 25px;
		height: 340px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		.page-title {
			position: absolute;
			width: 100%;
			padding: 60px 0 25px 35px;
			font-size: 3.2em;
			left: 0px;
			bottom: 0px;
			background: linear-gradient(0deg, #000000dd, transparent);
			color: #fff;
		}
	}
</style>
