<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="startPdfExport" class="screen-loading">
        <div class="loading2 white"></div>
        <p>Exportando PDF, aguarde.</p>
      </div>
    </transition>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      :html-to-pdf-options="htmlToPdfOptions"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section class="pdf-all" slot="pdf-content">
        <section class="pdf-item">
          <img
            class="pdf-logo"
            src="../assets/images/logo-blue.png"
            alt="logo cipatex"
          />
          <a class="pdf-header-link" href="https://catalogocipatex.com.br">https://catalogocipatex.com.br</a>
          <div class="pdf-header-line"></div>
          <h1 class="pdf-list-name">{{ title }}</h1>
        </section>       

        <div class="pdf-prods-holder">
          <section
            class="pdf-product"
            v-for="(prod, index) in products"
            :key="`prodPdf${index}`"
          >
            <img
              class="pdf-prod-image"
              :src="`${prod.coverImage}?_`"
              :alt="prod[currentLanguage]"
            />
            <div class="pdf-parent">
              <img
                :src="
                  categoryImage
                    ? `${categoryImage}?_`
                    : `${prod.categoryImage}?_`
                "
                alt="imagem da categoria"
              />
              <div class="content">
                <h1 class="prod">{{ prod[currentLanguage] }}</h1>
                <p class="cod">{{ `código: ${prod.code}` }}</p>
              </div>
            </div>
          </section>
        </div>
        <section>
          <img
            class="pdf-footer"
            src="../assets/images/pdf-footer.png"
            alt="footer"
          />
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    categoryImage: {
      type: String,
      default: "",
    },
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
    startPdfExport: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    startPdfExport: {
      handler: function(val) {
        if (val) this.loadPdfConfig();
      },
    },
  },
  data() {
    return {
      htmlToPdfOptions: {},
    };
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    async beforeDownload({ html2pdf, pdfContent }) {
      try {
        let pdfBlob = await html2pdf()
          .set(this.htmlToPdfOptions)
          .from(pdfContent)
          .toPdf()
          .output("blob");
        this.downloadBlob(pdfBlob);
      } catch (error) {
        this.$emit("exported");
        console.log(error);
      }
    },
    exportPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    loadPdfConfig() {
      let heightPixels = document.querySelector(".pdf-all")?.offsetHeight;
      let pdfHeight = heightPixels / 37;
      if (pdfHeight < 40) pdfHeight = 40;
      this.htmlToPdfOptions = {
        margin: 0,
        filename: this.title,
        image: {
          type: "jpeg",
          quality: 0.9,
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true,
          dpi: 72,
        },
        jsPDF: {
          unit: "cm",
          format: [33, pdfHeight],
          orientation: "portrait",
        },
      };
      setTimeout(() => {
        this.exportPdf();
      }, 200);
    },
    downloadBlob(blob) {
      let fileName = `${this.title}.pdf`;

      if (window.cordova?.platformId == "android") {
        this.saveAndroid(blob, fileName);
      } else if (window.cordova?.platformId == "ios") {
        this.saveIos(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.style.opacity = 0;
        link.style.position = "absolute";
        document.body.append(link);
        link.click();
        link.remove();
        this.$emit("exported");
      }
    },
    saveAndroid(blob, fileName) {
      let vm = this;
      window.resolveLocalFileSystemURL(
        cordova.file.externalDataDirectory,
        function(dirpar) {
          dirpar.getDirectory(
            "Cipatex Catalogo",
            { create: true },
            function(dir) {
              dir.getFile(fileName, { create: true }, function(file) {
                file.createWriter(function(fileWriter) {
                  fileWriter.onwriteend = function() {
                    cordova.plugins.fileOpener2.open(
                      file.nativeURL,
                      "application/pdf",
                      {
                        error: function(e) {
                          vm.$emit("exported");
                          console.log(
                            "Error status: " +
                              e.status +
                              " - Error message: " +
                              e.message
                          );
                        },
                        success: function() {
                          vm.$emit("exported");
                          console.log("file opened successfully");
                        },
                      }
                    );
                  };
                  fileWriter.seek(fileWriter.length);
                  fileWriter.write(blob);
                });
              });
            },
            function(err) {
              console.log(err);
            }
          );
        }
      );
    },
    saveIos(blob, fileName) {
      let vm = this;
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function(
        dir
      ) {
        dir.getFile(fileName, { create: true }, function(file) {
          file.createWriter(function(fileWriter) {
            fileWriter.onwriteend = function() {
              cordova.plugins.fileOpener2.open(
                file.nativeURL,
                "application/pdf",
                {
                  error: function(e) {
                    vm.$emit("exported");
                    console.log(
                      "Error status: " +
                        e.status +
                        " - Error message: " +
                        e.message
                    );
                  },
                  success: function() {
                    vm.$emit("exported");
                    console.log("file opened successfully");
                  },
                }
              );
            };
            fileWriter.seek(fileWriter.length);
            fileWriter.write(blob);
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf-all {
  position: relative;
  width: 33cm;
}
.pdf-footer {
  margin-top: 30px;
  margin-left: 0.5cm;
  width: 32cm;
}
.pdf-logo {
  width: 240px;
  margin: 25px 0 0 0.5cm;
}
.pdf-list-name {
  position: relative;
  font-size: 2em;
  color: var(--primary);
  text-align: left;
  margin-bottom: 35px;
  margin-left: 25px;
}
.pdf-header-link{
  position: absolute;
  font-size: 1em;
  color: var(--primary);
  right: 0.5cm;
  top: 65px;
  font-weight: bold;
}
.pdf-header-line{
  position: relative;
  border-bottom: var(--primary) solid 1px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.pdf-prods-holder {
  position: relative;
  width: 33cm;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 35px;
}
.pdf-product {
  position: relative;
  width: 10cm;
  margin-top: 45px;
  border-bottom: 1px dashed #aaa;
  .pdf-prod-image {
    position: relative;
    width: 100%;
  }
  .pdf-parent {
    position: relative;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    img {
      position: relative;
      width: 160px;
    }
    .content {
      position: relative;
      margin-left: 50px;
      p {
        margin-top: 15px;
      }
    }
  }
}
</style>
