<template>
  <div>
    <header>
      <img
        class="cadastro-logo-header tablet-hidden mobile-hidden"
        src="../assets/images/logo-blue.png"
        alt
      />
      <div class="container container500">
        <span v-translate>VOLTAR</span>
        <p
          class="text-desc text-bold text-center text-blue white-bold-desktop pwd-recovery"
        >
          {{ t("Recuperação de senha") }}
        </p>
      </div>
    </header>

    <div class="container container500">
      <transition enter-active-class="page-anim2">
        <div class="container container500">
          <div class="mb-2-desktop"></div>

          <div v-show="!validEmail">
            <div class="input-holder labeled mt-1">
              <p class="text-desc text-brown text-bold">
                {{ t("Informe o seu e-mail *") }}
              </p>
              <input
                v-model="user.email"
                type="email"
                placeholder="Ex: meuemail@dominio.com"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc warning-message mt-1"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button @click="checkEmail()" class="default bg-brown mt-2 mb-2">
              <span v-show="!loading" v-translate>Avançar</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>

          <div v-show="validEmail && !validConfirmationCode">
            <p class="text-desc warning-message">
              Enviamos um código para o e-mail {{ userEmail }}. <br /><br />Por
              favor, insira o código recebido no campo abaixo.
            </p>
            <div class="input-holder labeled mt-1">
              <p class="text-desc text-brown text-bold" v-translate>Código *</p>
              <input type="text" v-model="user.code" />
            </div>

            <button
              class="default bg-brown mt-2 mb-2"
              @click="checkConfirmationCode()"
            >
              <span v-show="!loading" v-translate>Avançar</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>

          <div v-show="validConfirmationCode">
            <div class="input-holder labeled mt-1">
              <p class="text-desc text-brown text-bold" v-translate>
                {{ t("Nova senha *") }}
              </p>
              <input type="password" v-model="user.newPassword" />
            </div>
            <div class="input-holder labeled mt-1">
              <p class="text-desc text-brown text-bold">
                {{ t("Confirmar senha *") }}
              </p>
              <input type="password" v-model="user.password2" />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="text-desc warning-message mt-1"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="success"
                class="text-desc success mt-1"
                style="color:#60c360;"
              >
                {{ t("Senha alterada com sucesso.") }}
                <br />
                <router-link to="/login" style="color:#60c360;">
                  <b v-translate>Faça login</b>
                </router-link>
                {{ t("agora mesmo.") }}
              </p>
            </transition>

            <button
              v-show="!success"
              class="default bg-brown mt-2 mb-2"
              @click="changePassword()"
            >
              <span v-show="!loading" v-translate>Trocar senha</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";

export default {
  name: "RecuperarSenha",
  data() {
    return {
      validEmail: false,
      validConfirmationCode: false,
      user: {
        email: "",
        code: null,
        newPassword: "",
        password2: "",
      },
      loading: false,
      warningMessage: "",
      success: false,
      userEmail: "",
    };
  },
  created() {},
  methods: {
    checkEmail() {
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe um e-mail válido."
        ];
        return;
      }

      this.loading = true;
      this.warningMessage = "";

      http
        .post(`user/forgotPassword?email=${this.user.email}`)
        .then((response) => {
          this.userEmail = response.data;
          this.validEmail = true;
          this.warningMessage = "";
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.warningMessage = this.$translate.locale[
              "Usuário não encontrado em nosso sistema."
            ];
          } else {
            this.warningMessage = err.response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkConfirmationCode() {
      this.loading = true;
      http
        .post("user/forgotPassword/validate", this.user)
        .then((response) => {
          if (response) {
            this.warningMessage = "";
            this.validConfirmationCode = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage = this.$translate.locale[
            "Parece que o código informado está incorreto. Verifique e tente novamente."
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePassword() {
      if (!this.user.newPassword) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe sua nova senha"
        ];
        return;
      }
      if (!this.user.password2) {
        this.warningMessage = this.$translate.locale[
          "Por favor, confirme a sua nova senha"
        ];
        return;
      }
      if (this.user.newPassword != this.user.password2) {
        this.warningMessage = this.$translate.locale[
          "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente"
        ];
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      http
        .post("user/resetPassword", this.user)
        .then((response) => {
          if (response) {
            this.success = true;
          }
        })
        .catch((err) => {
          this.warningMessage = err.response.data;
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.pwd-recovery {
  position: absolute;
  right: 0;
  left: 0;
}

header .container img.back {
  z-index: 9;
}

.text-desc a {
  font-size: 1em;
}

.text-desc.success {
  text-align: center;
}
</style>
