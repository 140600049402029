<template>
  <div class="page">
    <Header :showBackButton="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <div class="news-title-controls">
          <h1 class="page-title page-anim2 mb-1">
            {{ t("Novidades") }}
          </h1>
          <div class="controls">
            <font-awesome-icon
              class="news-prev"
              :icon="['fas', 'chevron-left']"
            />
            <font-awesome-icon
              class="news-next"
              :icon="['fas', 'chevron-right']"
            />
          </div>
        </div>

        <div v-show="!loadingRelated" class="news-holder">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <ProductItem
                v-for="prod in products"
                :key="prod.id"
                :id="prod.id"
                :image="prod.coverImage"
                :name="prod[currentLanguage]"
                :showFavoriteButton="false"
                class="swiper-slide prod pointer"
              />
              <div
                @click="$router.push('/novidades')"
                class="swiper-slide prod last pointer"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
                <p>{{ t("Ver todas as novidades") }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-show="loadingRelated" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <h1 class="page-title mt-2" v-translate>Mercados</h1>

        <div v-show="loadingSegments" class="flex-center mt-3">
          <div class="loading1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div v-show="!loadingSegments" class="items-holder">
          <router-link
            v-for="(item, index) in markets"
            :key="index"
            :to="`/mercado/${item.id}`"
            class="market-item pointer"
          >
            <img :src="item.image" />
            <div class="text-holder">
              <p>{{ item[currentLanguage] }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Swiper from "swiper/bundle";
import ProductItem from "../components/ProductItem";
export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      warningMessage: "",
      loadingSegments: true,
      loadingRelated: true,
      products: [],
      markets: [],
    };
  },
  created() {
    this.getNews();
    this.getMarkets();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    getMarkets() {
      http
        .get(
          "category/segment?orderBy=namePt-&take=-1",
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.markets = response.data.list || [];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingSegments = false;
        });
    },
    getNews() {
      http
        .get(
          `product/list?orderBy=timestamp&take=15&page=1`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = response.data.list;
            this.initSlider();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingRelated = false;
        });
    },
    initSlider() {
      setTimeout(() => {
        new Swiper(".swiper-container", {
          freeMode: true,
          slidesPerView: "auto",
          spaceBetween: 20,
          navigation: {
            nextEl: ".news-next",
            prevEl: ".news-prev",
          },
        });
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.news-title-controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .controls {
    position: relative;
    width: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 1.4em;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
  }
}

.prod {
  position: relative;
  width: 140px !important;
  max-height: 167px;
  overflow: hidden;
  transition: margin 0.2s ease;
}

.prod img {
  position: relative;
  width: 100%;
}

.prod.last {
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.prod.last:hover > svg,
.prod.last:hover > p {
  color: var(--primary);
}

.prod.last svg {
  font-size: 2.4em;
  transition: color 0.25s ease;
}

.prod.last p {
  margin: 12px 10px 0 12px;
  font-size: 1.1em;
  text-align: center;
  transition: color 0.25s ease;
}

.items-holder {
  position: relative;
  width: 100%;
  margin: 10px auto 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  justify-content: space-between;
}

.market-item {
  position: relative;
  width: 100%;
  max-width: 450px;
  padding-bottom: 45%;
  overflow: hidden;
  margin: 0;
  border-radius: 10px;
  background-color: var(--gray1);
}

.market-item img {
  position: absolute;
  max-width: 100%;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  margin: 0 auto;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 10px;
}

.market-item .text-holder {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  margin: 0 0 0 20px;
}

.market-item .text-holder p {
  font-size: 1.15em;
  color: var(--blue);
  text-transform: uppercase;
}
</style>
