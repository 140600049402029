<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ list.name }}
        </h1>

        <p v-show="products.length < 1 && !loading" class="mt-2">
          {{ t("Esta coleção está vazia.") }}
        </p>

        <div
          v-show="products.length > 0"
          @click="exportPdf"
          class="pdf-button mt-1-desktop mb-2-mobile mobile12"
        >
          <span v-translate>Exportar pdf</span>
          <font-awesome-icon :icon="['far', 'file-pdf']" />
        </div>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <div v-show="!loading" class="products-holder mt-2-desktop">
          <ProductItem
            v-for="prod in products"
            :key="prod.id"
            :id="prod.id"
            :image="prod.coverImage"
            :name="prod[currentLanguage]"
            :showRemoveButton="true"
            @removeProduct="removeProductFromList"
          />
        </div>
      </div>
    </div>

    <PdfProductsExport
      :title="list.name"
      :products="products"
      :startPdfExport="startPdfExport"
      @exported="endPdfExport"
    />

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import PdfProductsExport from "@/components/PdfProductsExport";

export default {
  name: "UserCollectionIntern",
  components: {
    Header,
    Navbar,
    ProductItem,
    PdfProductsExport,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      list: {},
      products: [],
      startPdfExport: false,
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    exportPdf() {
      this.startPdfExport = true;
    },
    endPdfExport() {
      this.startPdfExport = false;
    },
    removeProductFromList(prodId) {
      let data = { userListId: this.list.id, productId: prodId };
      let objToSend = { ...authentication.getHeaders(), ...{ data: data } };

      http
        .delete("userList/remove/product", objToSend)
        .then(() => {
          this.products = this.products.filter((prod) => prod.id !== prodId);
          this.$toast({ text: this.$translate.locale["Produto removido"] });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProducts() {
      http
        .get(
          `userList?id=${this.$route.params.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.list = response.data;
            this.products = this.list.products.map((prod) => {
              prod.categoryImage = prod.categories[0]?.parent?.image;
              return prod;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  width: 140px;
  height: 42px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
  span {
    margin-right: 15px;
    font-size: 1.2em;
    color: var(--primary);
  }
  svg {
    font-size: 1.3em;
    color: var(--primary);
  }
}
</style>
