<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="opened" @click="closeIt" class="bg-cover"></div>
    </transition>

    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <div v-show="opened" class="holder">
        <div class="header">
          <h1 v-translate>Filtro Técnico</h1>
          <font-awesome-icon
            class="pointer"
            @click="closeIt"
            :icon="['fas', 'chevron-right']"
          />
        </div>

        <div class="content">
          <h1 v-translate>Mercados</h1>
          <div class="tags">
            <div
              v-for="seg in segments"
              :key="seg.id"
              :class="`tag pointer ${seg.selected ? 'active' : ''}`"
              @click="setSelectedSegment(seg.id)"
            >
              {{ seg[currentLanguage] }}
            </div>
          </div>

          <h1 class="mt-1" v-translate>Linha ou Coleção</h1>
          <select class="pointer" v-model="collectionId">
            <option
              v-for="coll in collections"
              :key="coll.id"
              :value="coll.id"
              >{{ coll[currentLanguage] }}</option
            >
          </select>

          <h1 class="mt-1" v-translate>Cores</h1>
          <div class="colors">
            <div
              v-for="col in colors"
              :key="col.id"
              :value="col.id"
              :class="`color pointer ${col.selected ? 'active' : ''}`"
              :style="`background:${col.color}`"
              @click="setSelectedColor(col.id)"
            ></div>
          </div>

          <h1 class="mt-1" v-translate>Tipo de Aplicação</h1>
          <select class="pointer" v-model="destinationId">
            <option
              v-for="dest in destinations"
              :key="dest.id"
              :value="dest.id"
              >{{ dest[currentLanguage] }}</option
            >
          </select>
          <transition enter-active-class="animated fadeIn">
            <p v-show="warningMessage != ''" class="warning red">
              {{ warningMessage }}
            </p>
          </transition>
          <button
            class="app-btn"
            style="background-color:#5889da;"
            @click="setFilters"
          >
            <span v-translate>Aplicar</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";
export default {
  name: "FilterWidget",
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      language: "",
      segments: [],
      collections: [],
      colors: [],
      destinations: [],
      segmentId: "",
      colorId: "",
      collectionId: "",
      destinationId: "",
      warningMessage: "",
    };
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  created() {
    this.getSegments();
    this.getCollections();
    this.getColors();
    this.getDestinations();
  },
  methods: {
    setFilters() {
      this.$navigate(
        `/filtro?segmentId=${this.segmentId}&collectionId=${this.collectionId}&colorId=${this.colorId}&destinationId=${this.destinationId}`
      );
    },
    setSelectedSegment(segmentId) {
      this.segmentId = segmentId;
      this.setSegments();
    },
    setSelectedColor(colorId) {
      this.colorId = colorId;
      this.setColor();
    },
    setSegments() {
      this.segments = this.segments.map((d) => {
        d.selected = d.id === this.segmentId;
        console.log(`${d.selected} id ${d.id}`);
        return d;
      });
    },
    setColor() {
      this.colors = this.colors.map((c) => {
        c.selected = c.id === this.colorId;
        return c;
      });
    },
    getDestinations() {
      http
        .get(
          "destination/list?orderBy=namePt-&take=-1",
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            var all = [{ id: "", namePt: "Todos" }];
            this.destinations = [...all, ...response.data.list];

            if (this.$route.query.destinationId) {
              this.destinationId = this.$route.query.destinationId;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getColors() {
      http
        .get("product/getColors", authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            var all = [
              {
                id: "",
                color:
                  "linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red)",
                selected: true,
              },
            ];
            this.colors = [...all, ...response.data];

            if (this.$route.query.colorId) {
              this.setSelectedColor(this.$route.query.colorId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCollections() {
      http
        .get(
          "collection/list?orderBy=namePt-&take=-1",
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            var all = [{ id: "", namePt: "Todos" }];
            this.collections = [...all, ...response.data.list];

            if (this.$route.query.collectionId) {
              this.collectionId = this.$route.query.collectionId;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSegments() {
      http
        .get(
          "category/segment?orderBy=namePt-&take=-1",
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            var all = [
              {
                id: "",
                namePt: "Todos",
                nameEn: "All",
                nameEs: "Todos",
                selected: true,
              },
            ];
            this.segments = [...all, ...response.data.list];

            if (this.$route.query.segmentId) {
              this.setSelectedSegment(this.$route.query.segmentId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setLanguage(lang) {
      this.language = lang;
    },
    closeIt() {
      this.$emit("closeFilter");
    },
  },
};
</script>
<style scoped>
.holder {
  position: fixed;
  width: 80vw;
  height: 100vh;
  max-width: 480px;
  border-radius: 10px 0 0 10px;
  background-color: #fff;
  z-index: 10001;
  margin: 0;
  top: 0;
  right: 0;
  overflow: auto;
}

.header {
  position: relative;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1,
.header svg {
  color: #fff;
  font-size: 2em;
}

.content {
  position: relative;
  width: 100%;
  padding: 20px;
}

h1 {
  font-size: 1.2em;
}

.tags,
.colors {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: -4px;
  margin-right: -4px;
}

.tags .tag {
  position: relative;
  padding: 6px 14px;
  border-radius: 3px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
}

.tags .tag.active {
  background-color: #5889da;
  color: #fff;
}

select {
  position: relative;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #ddd;
}

.color {
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  box-shadow: 0 1px 5px 0 #00000062;
  margin: 6px;
}

.color.active {
  box-shadow: 0px 2px 8px 0 #000000;
}

@media screen and (min-width: 1160px) {
}
</style>
