<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1">
      <section v-show="!productLoading" class="initial">
        <div id="productImagesSlider" class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="img-slide swiper-slide"
              v-for="(image, index) in product.images"
              :key="index"
            >
              <img :src="image.path" />
            </div>
          </div>
          <div id="SliderNext" class="banner-next">
            <font-awesome-icon icon="chevron-right" />
          </div>
          <div id="SliderPrev" class="banner-prev">
            <font-awesome-icon icon="chevron-left" />
          </div>
        </div>
        <div class="container prod-desc-holder">
          <img class="brand-img" :src="categoryImage" alt="imagem da marca" />
          <div class="prod-desc">
            <h1>{{ product[currentLanguage] }}</h1>
            <p>{{ `Cod: ${product.code}` }}</p>
          </div>
          <div class="options-holder">
            <font-awesome-icon
              @click="showFavoriteOptions = true"
              class="pointer"
              :icon="['far', 'bookmark']"
            />
            <font-awesome-icon
              v-if="isMobileDevice"
              :icon="['far', 'share-square']"
              @click="setShareImage"
            />
            <div v-show="downloadingImage" class="loading2"></div>
            <font-awesome-icon
              v-show="!isMobileDevice && !downloadingImage"
              class="pointer"
              :icon="['fas', 'file-download']"
              @click="downloadImage"
            />
            <font-awesome-icon
              class="pointer"
              :icon="['fas', 'expand']"
              @click="imageFullscreen"
            />
          </div>
        </div>
      </section>
      <div class="container mt-1">
        <h1 class="uppercase text-desc text-primary mt-2">
          {{ t("Linha") }} {{ firstCategory }}
        </h1>

        <div v-show="loadingRelated" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <p v-show="!loadingRelated && relatedProducts.length < 1" class="mt-2">
          {{ t("Nenhum produto da mesma linha encontrado") }}
        </p>

        <div class="products-holder mt-1">
          <ProductItem
            v-for="prod in relatedProducts"
            :key="prod.id"
            :id="prod.id"
            :code="prod.code"
            :image="prod.coverImage"
            :name="prod[currentLanguage]"
          />
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showZoomableImage" class="fullscreen">
        <div class="close" @click="imageFullscreen(false)">
          {{ t("Fechar") }}
        </div>
        <transition
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
        >
          <div
            v-show="showZoomableImage"
            id="zoomImageContainer"
            class="img-holder"
          >
            <pinch-zoom>
              <img :src="product.images[zoomableImageIndex].path" alt="" />
            </pinch-zoom>
          </div>
        </transition>
        <div class="bottom-holder">
          <div
            @click="setImage('prev')"
            class="action pointer"
            :class="{ inactive: zoomableImageIndex == 0 }"
          >
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
            <p v-translate>Anterior</p>
          </div>
          <div
            @click="setImage('next')"
            class="action pointer"
            :class="{
              inactive: zoomableImageIndex == product.images.length - 1,
            }"
          >
            <p v-translate>Próxima</p>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </div>
        </div>
      </div>
    </transition>

    <FavoriteListsOptions
      :id="product.id"
      :image="product.images[0].path"
      :showComponent="showFavoriteOptions"
      @closeComponent="closeFavoriteOptions"
    />

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import Swiper from "swiper/bundle";
import FavoriteListsOptions from "@/components/FavoriteListsOptions";

export default {
  name: "ProductDetails",
  components: {
    Header,
    Navbar,
    ProductItem,
    FavoriteListsOptions,
  },
  data() {
    return {
      warningMessage: "",
      productLoading: true,
      loadingRelated: true,
      downloadingImage: false,
      showFavoriteOptions: false,
      product: {
        images: [
          {
            path: "",
          },
        ],
        categories: [
          {
            id: "",
          },
        ],
      },
      relatedProducts: [],
      showZoomableImage: false,
      zoomableImageIndex: 0,
    };
  },
  computed: {
    userIsLoggedIn() {
      return authentication.isLoggedIn();
    },
    categoryImage() {
      return this.product.categories[0]?.parent?.image;
    },
    firstCategory() {
      return this.product.categories[0][this.currentLanguage] || "";
    },
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
    isMobileDevice() {
      return (
        window.cordova?.platformId == "android" ||
        window.cordova?.platformId == "ios"
      );
    },
  },
  created() {
    http
      .get(`product?id=${this.$route.params.id}`, authentication.getHeaders())
      .then((response) => {
        if (response.status === 200) {
          this.product = response.data;
          this.loadRelatedProducts();
          this.startSlider();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.productLoading = false;
      });
  },
  methods: {
    closeFavoriteOptions() {
      this.showFavoriteOptions = false;
    },
    startSlider() {
      setTimeout(() => {
        new Swiper("#productImagesSlider", {
          slidesPerView: 1,
          spaceBetween: 0,
          autoHeight: false,
          navigation: {
            nextEl: "#SliderNext",
            prevEl: "#SliderPrev",
          },
        });
      }, 200);
    },
    shareSuccess() {
      this.$emit("shareImageReceived");
    },
    shareError() {
      this.$emit("shareImageReceived");
    },
    share(imageUrl) {
      let vm = this; // vuejs reference
      let options;

      if (window.cordova?.platformId === "ios") {
        options = {
          message: "", // not supported on some apps (Facebook, Instagram)
          subject: `${this.product[this.currentLanguage]} - Código: ${
            this.product.code
          }`, // for email
          files: [imageUrl], // an array of filenames either locally or remotely
        };
      } else {
        options = {
          message: `${this.product[this.currentLanguage]} - Código: ${
            this.product.code
          }`,
          subject: `${this.product[this.currentLanguage]} - Código: ${
            this.product.code
          }`,
          files: [imageUrl],
        };
      }

      window.plugins.socialsharing.shareWithOptions(
        options,
        vm.shareSuccess,
        vm.shareError
      );
    },
    setShareImage() {
      let img =
        document.querySelector(".swiper-slide-active img")?.src ||
        this.product.images[0].path;
      this.share(img);
    },
    downloadImage() {
      const imgPath =
        document.querySelector(".swiper-slide-active img")?.src ||
        this.product.images[0].path;
      console.log(imgPath);
      let productImageId = this.product.images.filter(
        (i) => i.path == imgPath
      )[0].id;
      console.log(productImageId);
      this.downloadingImage = true;
      http
        .get(`Product/${productImageId}/File`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          this.downloadBlob(blob);
        })
        .catch((err) => {
          console.log(err);
          this.$swal.fire({
            type: "error",
            title: this.$translate.locale["Algo deu errado"],
            text: this.$translate.locale[
              `Tente novamente em instantes. Se o problema persistir, por favor, informe o suporte.`
            ],
            confirmButtonColor: "var(--red1)",
          });
        })
        .finally(() => {
          this.downloadingImage = false;
        });
    },
    downloadBlob(blob) {
      var fileName = `${this.product[this.currentLanguage]}.png`;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.style.opacity = 0;
      link.style.position = "absolute";
      document.body.append(link);
      link.click();
      link.remove();
    },
    setImage(navigate) {
      if (
        navigate == "next" &&
        this.zoomableImageIndex < this.product.images.length - 1
      ) {
        this.zoomableImageIndex++;
        return;
      }
      if (navigate == "prev" && this.zoomableImageIndex > 0) {
        this.zoomableImageIndex--;
        return;
      }
    },
    imageFullscreen(show = true) {
      let html = document.querySelector("html");
      if (show) {
        this.showZoomableImage = true;
        html.style.overflow = "hidden";
      } else {
        this.zoomableImageIndex = 0;
        this.showZoomableImage = false;
        html.style.overflow = "initial";
      }
    },
    loadRelatedProducts() {
      http
        .get(
          `product/list?categoryId=${this.product.categories[0].id}&take=-1`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.relatedProducts = response.data.list.filter(
              (p) => p.id != this.product.id
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingRelated = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section.initial {
  position: relative;
  width: 100vw;
  max-height: 450px;
  padding: 0;
  margin: 52px 0 0 0;
  overflow: hidden;
}

.prod-desc-holder {
  position: absolute;
  max-width: 450px;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 8px;
  z-index: 2;

  .brand-img {
    position: relative;
    max-width: 42px;
    margin-right: 10px;
    border-radius: 5px;
  }

  .prod-desc {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h1 {
      color: #333;
      font-size: 1em;
      text-transform: uppercase;
    }
  }

  .options-holder {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    svg {
      font-size: 1.5em;
      margin-left: 18px;
    }
    .loading2 {
      margin-left: 18px;
    }
  }
}

.img-slide {
  position: relative;
  height: 460px;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    margin: 0 auto;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    max-height: 190%;
  }
}

.banner-next,
.banner-prev {
  position: absolute;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #ffffff22;
  left: 0.5rem;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  transition: background-color 0.2s ease, opacity 0.2s ease;

  &.swiper-button-disabled {
    opacity: 0.5;
  }

  svg {
    font-size: 1.6em;
    color: #fff;
    text-shadow: 1px 1px #000;
  }
}

.banner-next {
  left: initial;
}

.banner-prev {
  right: initial;
}

.fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .close {
    position: fixed;
    top: 1.1rem;
    right: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    padding: 4px 12px;
    color: #fff;
    cursor: pointer;
  }

  .bottom-holder {
    position: fixed;
    bottom: 1.1rem;
    width: 85vw;
    max-width: 580px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action {
      position: relative;
      display: flex;
      align-items: center;
      gap: 15px;
      &.inactive {
        opacity: 0.3;
      }
      p {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  }

  .img-holder {
    position: relative;
    width: 100vw;
    height: 80vh;
    max-height: calc(100vh - 120px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .pinch-zoom-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    img {
      position: relative;
      max-height: 80vh;
    }
  }
}

@media only screen and (min-width: 1160px) {
  section.initial {
    width: 80%;
    margin: 62px auto 0 auto;
    max-height: 550px;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
  }

  .img-slide {
    height: 550px;
  }

  .prod-desc-holder {
    left: initial;
    border-radius: 8px 0 0 8px;
  }

  .prod-desc-holder .prod-desc h1 {
    color: #333;
    font-size: 1.25em;
    text-transform: uppercase;
  }
}
</style>
