<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <div class="mb-1 desktop-hidden">
          <h1 class="page-title page-anim2" style="margin-bottom:10px;">
            {{ category[currentLanguage] }}
          </h1>
          <a
            v-show="category.hotsite"
            :href="category.hotsite"
            rel="noreferrer"
            target="_blank"
          >
            <b style="color:#1770c4;" v-translate>Saiba mais</b>
          </a>
        </div>

        <div class="section-image-holder desktop-hidden">
          <div class="bg-holder">
            <img :src="category.parent.image" />
          </div>
          <div class="front-image-holder">
            <img :src="category.image" :alt="category[currentLanguage]" />
          </div>
        </div>

        <div
          class="page-title-image tablet-hidden mobile-hidden"
          :style="`background-image:url(${category.parent.image})`"
        >
          <div class="front-image-holder">
            <img
              class="front"
              :src="category.image"
              :alt="category[currentLanguage]"
            />
          </div>
          <h1 class="page-title">{{ category[currentLanguage] }}</h1>
          <a
            class="link-hotsite"
            v-show="category.hotsite"
            :href="category.hotsite"
            rel="noreferrer"
            target="_blank"
          >
            <b v-translate>Saiba mais</b>
          </a>
        </div>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <div v-show="!loading" class="products-holder mt-3-desktop">
          <router-link
            v-for="subcat in subcategories"
            :key="subcat.id"
            class="card cat pointer"
            :to="`/subcategoria/${subcat.id}`"
          >
            <img :src="subcat.image" :alt="subcat[currentLanguage]" />
            <div class="text-holder">
              <p>{{ subcat[currentLanguage] }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default {
  name: "Category",
  components: {
    Header,
    Navbar,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      category: {
        parent: {
          image: "",
        },
        image: "",
      },
      subcategories: [],
      products: [],
    };
  },
  created() {
    this.getCategory();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    getCategory() {
      http
        .get(
          `category?id=${this.$route.params.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.category = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      http
        .get(
          `category/List?parentId=${this.$route.params.id}&take=-1`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.subcategories = response.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.section-image-holder {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 25px auto;

  .bg-holder {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 56%;
    margin: 0 auto 25px auto;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;

    img {
      position: absolute;
      max-width: 110%;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
    }
  }

  .front-image-holder {
    position: absolute;
    right: 2rem;
    top: -2.2rem;
    width: 130px;
    height: 125px;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: relative;
      max-width: 90%;
      display: block;
      margin: 0 auto;
    }
  }
}
.text-holder {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  margin: 0 0 0 4px;
  p {
    font-size: 1em;
    color: var(--blue);
    text-transform: uppercase;
  }
}

.card.cat {
  justify-content: flex-start;
}

.page-title-image {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 340px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  img.bg {
    max-height: 100%;
    border-radius: 8px;
  }
  img.front {
    position: absolute;
    max-height: 65%;
    border-radius: 8px;
    right: 120px;
    bottom: -40px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .page-title {
    position: absolute;
    width: 100%;
    padding: 60px 0 35px 35px;
    font-size: 3.2em;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(0deg, #000000dd, transparent);
    color: #fff;
  }

  .link-hotsite {
    position: absolute;
    padding: 30px 0 10px 35px;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;

    b {
      color: #1770c4;
    }
  }
}
</style>
