<template>
  <div class="page">
    <Header
      :showBackButton="true"
      :showLogo="false"
      @clearColortoneImage="clearColortone"
    />

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="processingPicture" class="loading-holder">
        <div class="loading1">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn">
      <div
        v-show="!userImage && !processingPicture"
        class="page-anim1 mt-header"
      >
        <div class="container vertical">
          <img
            class="colortone"
            src="../assets/images/colortone.jpg"
            alt="Colortone"
          />

          <p v-show="hasCamera" class="mt-2 text-center">
            {{
              t(
                "Tire uma foto para o aplicativo analisar as cores e descobrir quais os produtos que melhor combinam com o seu ambiente."
              )
            }}
          </p>

          <p v-show="!hasCamera" class="mt-2 text-center">
            Baixe nosso aplicativo para uma experiência mais completa.
          </p>

          <img
            v-show="hasCamera"
            @click="selectUserImage()"
            src="../assets/images/cam.png"
            class="cam"
          />
        </div>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn">
      <div v-show="userImage" class="page-anim1">
        <div class="result-holder">
          <img id="userImage" :src="userImage" class="user-image" />

          <div @click="clearColortone" class="clear-user-image">X</div>

          <transition enter-active-class="animated fadeInRight">
            <div v-show="userImage && !processingPicture" class="colors-holder">
              <h1 v-translate>Paleta de cores</h1>
              <p v-translate>Clique em uma cor para filtrar os produtos</p>
              <div
                v-for="(color, index) in colors"
                :key="index"
                class="color"
                :style="`background-color:rgb(${color.toString()});`"
                @click="loadProducts(`rgb(${color.toString()})`)"
              ></div>
            </div>
          </transition>

          <transition enter-active-class="animated fadeInUp">
            <div class="products-all">
              <div class="container">
                <h1
                  class="page-title page-anim2 mb-1"
                  style="margin-bottom:10px;"
                >
                  {{ t("Produtos referentes") }}
                </h1>

                <div v-show="loadingProducts" class="flex-center mt-2">
                  <div class="loading2"></div>
                </div>

                <div v-show="!loadingProducts" class="products-holder">
                  <ProductItem
                    v-for="(prod, index) in products"
                    :id="prod.id"
                    :key="index"
                    :image="prod.coverImage"
                    :name="prod.namePt"
                    :isFavorite="prod.isFavorite"
                    @favorite="checkFavorites"
                  />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
// import validators from "../util/validators";
// import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import ColorThief from "colorthief";
import favorites from "../util/favorites";

export default {
  name: "Colortone",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      userImage: "",
      products: [],
      colors: [],
      processingPicture: false,
      loadingProducts: false,
    };
  },
  mounted() {
    if (localStorage.colortoneImage) {
      this.userImage = localStorage.colortoneImage;
      this.extractImageColors();
    }
  },
  computed: {
    hasCamera() {
      let hasCam = false;
      try {
        hasCam = Camera;
      } catch (error) {
        console.log(error);
      }
      if (hasCam) {
        hasCam = true;
      }
      return hasCam;
    },
  },
  methods: {
    clearColortone() {
      this.userImage = "";
    },
    scrollToProducts() {
      setTimeout(() => {
        let distance = document.querySelector(".colors-holder").offsetTop;
        window.scroll({
          top: distance,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    },
    loadProducts() {
      this.products = false;
      this.loadingProducts = true;
      http
        .post(
          `product/colortone`,
          this.colors.map((c) => {
            return c.toString();
          })
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = response.data;
            console.log(this.products);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingProducts = false;
        });

      setTimeout(() => {
        this.scrollToProducts();
      }, 800);
    },
    extractImageColors() {
      let colorthief = new ColorThief();
      let img = document.querySelector("#userImage");

      img.addEventListener("load", () => {
        this.colors = colorthief.getPalette(img, 5);
        this.processingPicture = false;
        this.loadProducts();
      });
    },
    /* eslint-disable */
    setOptions(srcType) {
      try {
        var options = {
          // Some common settings are 20, 50, and 100
          quality: 50,
          destinationType: Camera.DestinationType.DATA_URL,
          // In this app, dynamically set the picture source, Camera or photo gallery
          sourceType: srcType,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE,
          allowEdit: false,
          correctOrientation: true, //Corrects Android orientation quirks
        };
      } catch (error) {
        console.log(error);
      }
      return options;
    },
    selectUserImage() {
      setTimeout(() => {
        this.processingPicture = true;
      }, 200);
      let srcType = Camera.PictureSourceType.CAMERA;
      let options = this.setOptions(srcType);
      let vm = this; // Vue reference
      navigator.camera.getPicture(
        function cameraSuccess(imageUri) {
          vm.userImage = "data:image/jpeg;base64," + imageUri;
          vm.extractImageColors();
        },
        function cameraError(error) {
          console.debug("Error getting user picture: " + error);
          vm.processingPicture = false;
        },
        options
      );
    },
    checkFavorites() {
      let allFavorites = favorites.list();
      if (!allFavorites) return;
      this.products.map((fav) => {
        if (allFavorites.includes(fav.id)) {
          fav.isFavorite = true;
        } else {
          fav.isFavorite = false;
        }
      });
    },
    /* eslint-enable */
  },
  watch: {
    userImage: {
      handler: function(val) {
        if (val) {
          localStorage.colortoneImage = val;
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          localStorage.removeItem("colortoneImage");
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.container.vertical {
  height: calc(100vh - 52px - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
img.colortone {
  width: 60%;
  max-width: 380px;
}

img.cam {
  position: relative;
  width: 50%;
  max-width: 300px;
  margin-top: 5rem;
}

.loading-holder {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-holder {
  position: relative;
  width: 100vw;
  max-height: 100vh;
  margin-top: 52px;

  .user-image {
    position: relative;
    width: 100%;
  }
}

.colors-holder {
  position: relative;
  width: 75%;
  max-width: 400px;
  min-width: 280px;
  padding: 15px;
  border-radius: 8px 0 0 8px;
  margin: -150px 0 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  h1,
  p {
    position: relative;
    width: 100%;
    display: block;
  }
  h1 {
    font-size: 1.4em;
  }
  .color {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    margin-top: 8px;
  }
}

.products-all {
  position: relative;
  margin-top: 40px;
  padding-bottom: 85px;
}

.clear-user-image {
  position: absolute;
  top: 44px;
  right: 5vw;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 100%;
  font-family: fontRegular;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
</style>
