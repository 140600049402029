<template>
  <div>
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container desktop4">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ t("Meus Dados") }}
        </h1>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <div v-show="!loading">
          <div class="input-item page-anim2">
            <span v-translate>Nome *</span>
            <input type="text" v-model="form.name" />
          </div>
          <div class="input-item page-anim2">
            <span v-translate>Telefone *</span>
            <the-mask
              v-model="form.phoneNumber"
              :mask="['(##) ####-####', '(##) #####-####']"
              :masked="false"
            />
          </div>
          <div class="input-item page-anim2">
            <span v-translate>Empresa</span>
            <input type="text" v-model="form.company" />
          </div>
          <div class="input-item page-anim2">
            <span>E-mail *</span>
            <input type="email" v-model="form.email" />
          </div>
        </div>

        <transition enter-active-class="animated fadeIn">
          <p v-show="warningMessage != ''" class="warning red">
            {{ warningMessage }}
          </p>
        </transition>

        <button
          @click="checkForm"
          class="btn desktop12 tablet12 mobile12 mx-auto page-anim3 mb-2"
        >
          <span v-show="!updating" v-translate>Atualizar Dados</span>
          <div v-show="updating" class="loading2 white"></div>
        </button>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default {
  name: "UserProfile",
  components: {
    Header,
    Navbar,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      updating: false,
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        company: "",
      },
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    checkForm() {
      if (this.form.name.length < 4) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe o seu nome."
        ];
        return;
      }
      if (this.form.phoneNumber.length < 10) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe um telefone válido."
        ];
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe um e-mail válido."
        ];
        return;
      }

      this.warningMessage = false;
      this.signUp();
    },
    signUp() {
      if (!this.updating) {
        this.warningMessage = "";
        this.updating = true;

        http
          .put(`user`, this.form, authentication.getHeaders())
          .then((response) => {
            if (response.status === 200) {
              this.warningMessage = this.$translate.locale[
                "Dados atualizados com sucesso!"
              ];
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.updating = false;
          });
      }
    },
    getUser() {
      http
        .get(
          `user/details/${authentication.getUser().info.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.form = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.terms-holder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  z-index: 9999;
}

.terms-holder .container {
  position: relative;
  width: 72%;
  max-width: 520px;
  min-width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.terms-holder .title {
  font-size: 1.6em;
  color: #fff;
}

.terms-holder p {
  color: #fff;
  text-align: justify;
  margin-top: 35px;
  letter-spacing: 1px;
}
</style>
