import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import Cadastro from "@/views/Cadastro";
import UserProfile from "@/views/UserProfile";
import RecuperarSenha from "@/views/RecuperarSenha";
import Language from "@/views/Language";
import Home from "@/views/Home";
import Colortone from "@/views/Colortone";
import NewProducts from "@/views/NewProducts";
import Category from "@/views/Category";
import Subcategory from "@/views/Subcategory";
import ProductDetails from "@/views/ProductDetails";
import Collection from "@/views/Collection";
import CollectionIntern from "@/views/CollectionIntern";
import UserCollections from "@/views/UserCollections";
import UserCollection from "@/views/UserCollectionIntern";
import Segment from "@/views/Segment";
import Search from "@/views/Search";
import Filters from "@/views/Filters";

Vue.use(VueRouter);

const vm = new Vue({});

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/home",
    alias: ["/home"],
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/colortone",
    name: "Colortone",
    component: Colortone,
    meta: {
      title: "Colortone",
    },
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    component: Cadastro,
    meta: {
      title: "Cadastro",
    },
  },
  {
    path: "/meus-dados",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      title: "Perfil do Usuário",
    },
  },
  {
    path: "/recuperar-senha",
    name: "RecuperarSenha",
    component: RecuperarSenha,
    meta: {
      title: "Recuperar Senha",
    },
  },
  {
    path: "/idioma",
    name: "Language",
    component: Language,
    meta: {
      title: "Idioma",
    },
  },
  {
    path: "/novidades",
    name: "NewProducts",
    component: NewProducts,
    meta: {
      title: "Novidades",
    },
  },
  {
    path: "/minhas-colecoes",
    name: "UserCollections",
    component: UserCollections,
    meta: {
      title: "Minhas Coleções",
    },
  },
  {
    path: "/minhas-colecoes/:id",
    name: "UserCollection",
    component: UserCollection,
    meta: {
      title: "Detalhes da coleção",
    },
  },
  {
    path: "/colecoes",
    name: "Collection",
    component: Collection,
    meta: {
      title: "Coleções",
    },
  },
  {
    path: "/colecoes/:id",
    name: "CollectionIntern",
    component: CollectionIntern,
    meta: {
      title: "Coleções - Lista",
    },
  },
  {
    path: "/mercado/:id",
    name: "Segment",
    component: Segment,
    meta: {
      title: "Mercado",
    },
  },
  {
    path: "/categoria/:id",
    name: "Category",
    component: Category,
    meta: {
      title: "Categoria",
    },
  },
  {
    path: "/subcategoria/:id",
    name: "Subcategory",
    component: Subcategory,
    meta: {
      title: "Subcategoria",
    },
  },
  {
    path: "/produto-detalhes/:id",
    name: "ProductDetails",
    component: ProductDetails,
    meta: {
      title: "Produto - Detalhes",
    },
  },
  {
    path: "/busca/:keyword",
    name: "Search",
    component: Search,
    meta: {
      title: "Resultado de busca",
    },
  },
  {
    path: "/filtro",
    name: "Filters",
    component: Filters,
    meta: {
      title: "Filtro",
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (from.path != to.path) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (!to.query.lang) {
    if (localStorage.language) {
      next({
        path: to.fullPath,
        query: { ...to.query, ...{ lang: localStorage.language } },
      });
    } else {
      localStorage.language = "br";
      next({ path: to.fullPath, query: { ...to.query, ...{ lang: "br" } } });
    }
  } else {
    localStorage.language = to.query.lang;
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title || "Cipatex - Catálogo";
  if (process.env.NODE_ENV !== "development") {
    gtag("config", window.GA_TRACKING_ID, {
      page_path: to.fullPath,
      send_page_view: true,
    });
  }

  vm.$translate.setLang(localStorage.language);

  if (to.path == "/") vm.$navigate("/home");
});

export default router;
