import authentication from "./authentication";
export default {
  list: () => {
    let favorites = authentication.getUser()?.info?.favorites || false;
    return favorites;
  },
  add: (id) => {
    let favorites = authentication.getUser().info.favorites || false;
    if (favorites) {
      favorites.push(id);
      let user = JSON.parse(localStorage.user);
      user.info.favorites = favorites;
      localStorage.user = JSON.stringify(user);
    }
  },
  remove: (id) => {
    let favorites = authentication.getUser().info.favorites || false;
    if (favorites) {
      favorites = favorites.filter((f) => f != id);
      let user = JSON.parse(localStorage.user);
      user.info.favorites = favorites;
      localStorage.user = JSON.stringify(user);
    }
  },
};
