<template>
  <header>
    <Search :opened="showSearch" @closeSearch="closeSearchComponent" />
    <div class="container">
      <div v-show="showBackButton" class="btn-back" @click="goBack">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>
      <img
        src="../assets/images/logo-blue.png"
        alt="Cipatex logo"
        class="logo mobile-hidden tablet-hidden"
      />
      <img
        v-show="showLogo"
        src="../assets/images/logo-blue.png"
        alt="Cipatex logo"
        class="logo desktop-hidden"
      />

      <ul
        class="navbar-desktop mobile-hidden tablet-hidden"
        v-show="showMenuLinks"
      >
        <li>
          <router-link to="/home">Home</router-link>
        </li>
        <li>
          <router-link to="/colecoes" v-translate>Coleções</router-link>
        </li>
        <li v-if="isMobileDevice">
          <router-link to="/colortone">Colortone</router-link>
        </li>
        <li v-show="userLoggedIn">
          <router-link to="/minhas-colecoes">{{
            t("Meus Favoritos")
          }}</router-link>
        </li>
        <li v-show="userLoggedIn">
          <router-link to="/meus-dados" v-translate>Meus Dados</router-link>
        </li>
        <li>
          <a
            href="https://cipatex.com.br"
            target="_blank"
            style="color:var(--primary);font-family:fontBold;"
            >SITE CIPATEX</a
          >
        </li>
      </ul>

      <font-awesome-icon
        :icon="['fas', 'search']"
        class="search-icon pointer"
        v-show="showSearchIcon"
        @click="showSearch = !showSearch"
      />
      <div
        @click="showMenu = !showMenu"
        v-show="showMenuIcon"
        class="btn-menu pointer"
      >
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>

    <Menu :opened="showMenu" @closeMenu="closeMenuComponent" />
  </header>
</template>

<script>
import authentication from "../util/authentication";
import Search from "../components/Search";
import Menu from "../components/Menu";

export default {
  name: "Header",
  components: {
    Search,
    Menu,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showMenuIcon: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    showMenuLinks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      opened: false,
      showSearch: false,
      showMenu: false,
    };
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
    isMobileDevice() {
      return (
        window.cordova?.platformId == "android" ||
        window.cordova?.platformId == "ios"
      );
    },
  },
  methods: {
    closeMenuComponent() {
      this.showMenu = false;
    },
    closeSearchComponent() {
      this.showSearch = false;
    },
    goBack() {
      if (this.$route.name === "Colortone" && localStorage.colortoneImage) {
        this.$emit("clearColortoneImage");
        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  width: 100vw;
  height: 52px;
  background-color: #fff;
  top: 0;
  left: 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.22);
  z-index: 999;
  .container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.btn-back {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
  cursor: pointer;
  svg {
    color: #fff;
  }
}

img.logo {
  position: relative;
  max-height: 60%;
}

.search-icon {
  position: relative;
  font-size: 1.4em;
  margin-left: auto;
  color: var(--dark4);
}

.btn-menu {
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .line {
    position: relative;
    width: 65%;
    height: 2px;
    background-color: var(--primary);
    &:nth-child(2) {
      position: relative;
      margin: 5px 0;
    }
  }
}

.navbar-desktop {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 65px;
  li {
    position: relative;
    margin-right: 25px;
    a {
      position: relative;
      font-size: 1.2em;
      &.router-link-active {
        color: var(--secondary);
      }
    }
  }
}

@media screen and (min-width: 1160px) {
  header {
    height: 62px;
  }
}
</style>
