export default {
  br: {
    "ENTRAR/CADASTRAR": "ENTRAR/CADASTRAR",
    "Esta coleção está vazia.": "Esta coleção está vazia.",
    "Produto adicionado": "Produto adicionado",
    "Salvar produto em:": "Salvar produto em:",
    "Nome da nova coleção *": "Nome da nova coleção *",
    "Produto removido": "Produto removido",
    "Coleção criada": "Coleção criada",
    "Coleção excluída": "Coleção excluída",
    "Tem certeza que deseja excluir esta coleção?":
      "Tem certeza que deseja excluir esta coleção?",
    "Excluir coleção": "Excluir coleção",
    "Minhas coleções": "Minhas coleções",
    Criar: "Criar",
    Cancelar: "Cancelar",
    "Nome da coleção *": "Nome da coleção *",
    "nova coleção": "nova coleção",
    "Exportar pdf": "Exportar pdf",
    "Algo deu errado": "Algo deu errado",
    "Tente novamente em instantes. Se o problema persistir, por favor, informe o suporte.":
      "Tente novamente em instantes. Se o problema persistir, por favor, informe o suporte.",
    Mais: "Mais",
    "Nenhum produto da mesma linha encontrado":
      "Nenhum produto da mesma linha encontrado",
    "Por favor, digite alguma coisa.": "Por favor, digite alguma coisa.",
    linha: "linha",
    "Remoção dos favoritos": "Remoção dos favoritos",
    "Tem certeza que deseja remover este produto dos favoritos?":
      "Tem certeza que deseja remover este produto dos favoritos?",
    Remover: "Remover",
    COLEÇÕES: "COLEÇÕES",
    Coleções: "Coleções",
    "Encerramento:": "Encerramento:",
    Produtos: "Produtos",
    "Meus Favoritos": "Meus Favoritos",
    "Meus Dados": "Meus Dados",
    "Nome *": "Nome *",
    Telefone: "Telefone",
    Empresa: "Empresa",
    "Atualizar Dados": "Atualizar Dados",
    "Por favor, informe o seu nome.": "Por favor, informe o seu nome.",
    "Por favor, informe um e-mail válido.":
      "Por favor, informe um e-mail válido.",
    "Dados atualizados com sucesso!": "Dados atualizados com sucesso!",
    "Por favor, informe uma senha com pelo menos 6 caracteres.":
      "Por favor, informe uma senha com pelo menos 6 caracteres.",
    "A senha e a verificação de senha estão diferentes.":
      "A senha e a verificação de senha estão diferentes.",
    "Informe o seu e-mail *": "Informe o seu e-mail *",
    "Senha alterada com sucesso.": "Senha alterada com sucesso.",
    "Faça login": "Faça login",
    "agora mesmo.": "agora mesmo",
    "Usuário não encontrado em nosso sistema.":
      "Usuário não encontrado em nosso sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que o código informado está incorreto. Verifique e tente novamente.",
    "Por favor, informe sua nova senha": "Por favor, informe sua nova senha",
    "Por favor, confirme a sua nova senha":
      "Por favor, confirme a sua nova senha",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente",
  },
  en: {
    "ENTRAR/CADASTRAR": "SIGN IN/SIGN UP",
    "Esta coleção está vazia.": "This collection is empty.",
    "Produto adicionado": "Product saved",
    "Salvar produto em:": "Save product at:",
    "Nome da nova coleção *": "New collection name *",
    "Produto removido": "Product removed",
    "Coleção criada": "Collection created",
    "Coleção excluída": "Collection deleted",
    "Tem certeza que deseja excluir esta coleção?":
      "Are you sure you want to permanently remove this collection?",
    "Excluir coleção": "Delete collection",
    "Minhas coleções": "My collections",
    Criar: "Create",
    Cancelar: "Cancel",
    "Nome da coleção *": "Collection name *",
    "nova coleção": "new collection",
    "Exportar pdf": "Export pdf",
    "Algo deu errado": "Something is wrong",
    "Tente novamente em instantes. Se o problema persistir, por favor, informe o suporte.":
      "Please try again in a moment. If the problem persists, please notify support.",
    Mais: "More",
    "Nenhum produto da mesma linha encontrado":
      "No products found from the same line",
    "Por favor, digite alguma coisa.": "Please type something.",
    Fechar: "Close",
    Próxima: "Next",
    Anterior: "Previous",
    Aceito: "Accept",
    "Não aceito": "Decline",
    "Leia os detalhes dos termos": "Read the details of the terms",
    Cadastro: "Sign up",
    Novidades: "News",
    Mercados: "Markets",
    "Mercado:": "Market:",
    "Ver todas as novidades": "See all news",
    "FILTRO TÉCNICO": "TECHNICAL FILTER",
    "Saiba mais": "Learn more",
    IDIOMAS: "LANGUAGES",
    "TERMOS DE USO": "TERMS OF USE",
    SAIR: "LOG OUT",
    Calculadora: "Calculator",
    "Calcule o metro linear": "Calculate the linear meter",
    "Largura (m):": "Width (m):",
    "Comprimento (m):": "Length (m):",
    ou: "or",
    "Preencha o nº de m²:": "Fill in the m²",
    "Número em metros lineares": "number in linear meters",
    Busca: "Search",
    Buscar: "Search",
    "Busque por código, nome, linha, etc.": "Search by code, name, line, etc.",
    "Filtro Técnico": "Technical Filter",
    "Linha ou Coleção": "Line or Collection:",
    Cores: "Colors",
    "Tipo de Aplicação": "Type of Application",
    Aplicar: "Apply",
    "Remoção dos favoritos": "Favorite Removal",
    "Tem certeza que deseja remover este produto dos favoritos?":
      "Are you sure you want to remove it from your favorites?",
    Remover: "Remove",
    COLEÇÕES: "COLLECTIONS",
    Coleções: "Collections",
    "Encerramento:": "Ending date:",
    Produtos: "Products",
    "Meus Favoritos": "Favorites",
    "Meus Dados": "Profile",
    "Nome *": "Name *",
    Telefone: "Phone number",
    Empresa: "Company",
    "Atualizar Dados": "Update info",
    "Por favor, informe o seu nome.": "Please, inform your name.",
    "Por favor, informe um e-mail válido.": "Please, inform a valid e-mail.",
    "Dados atualizados com sucesso!": "Data updated successfully!",
    "Tire uma foto para o aplicativo analisar as cores e descobrir quais os produtos que melhor combinam com o seu ambiente.":
      "Take a picture for the application to analyze the colors and find out which products best match your environment.",
    "Paleta de cores": "Color palette",
    "Clique em uma cor para filtrar os produtos":
      "Pick a color to filter the products",
    "Produtos referentes": "Referring products",
    "Já sou cadastrado": "I'm already registered",
    Senha: "Password",
    "Senha *": "Password *",
    "Por favor, informe uma senha com pelo menos 6 caracteres.":
      "Please enter a password with at least 6 characters.",
    "A senha e a verificação de senha estão diferentes.":
      "The password and password verification are different.",
    Entrar: "Log in",
    "Ainda não é cadastrado?": "Not registered yet?",
    Cadastrar: "Sign up",
    "Entrar como visitante": "Log in as a visitor",
    'Para ter acesso a 100% das funcionalidades do catálogo como "favoritos", "PDFs personalizados" é necessário estar cadastrado.':
      'To have access to 100% of the features of the catalog as "favorites", "Custom PDFs" you must be registered.',
    Linha: "Line",
    VOLTAR: "BACK",
    "Recuperação de senha": "Password recovery",
    "Informe o seu e-mail *": "Inform your e-mail *",
    Avançar: "Next",
    "Código *": "Code *",
    "Nova senha *": "New password *",
    "Confirmar senha *": "Confirm password *",
    "Senha alterada com sucesso.": "Password changed successfully.",
    "Faça login": "Login",
    "agora mesmo.": "right now",
    "Trocar senha": "Change password",
    "Usuário não encontrado em nosso sistema.": "User not found in our system.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "It appears that the code entered is incorrect. Check and try again.",
    "Por favor, informe sua nova senha": "Please enter your new password",
    "Por favor, confirme a sua nova senha": "Please confirm your new password",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Your password and password confirmation are different. Please check and try again",
    "Proteção de dados": "Data protection",
  },
  es: {
    "ENTRAR/CADASTRAR": "Iniciar Sesión/Inscribirse",
    "Esta coleção está vazia.": "esta colección está vacía",
    "Produto adicionado": "Producto guardado",
    "Salvar produto em:": "Guardar producto en:",
    "Nome da nova coleção *": "Nuevo nombre de colección *",
    "Produto removido": "producto eliminado",
    "Coleção criada": "Colección creada",
    "Coleção excluída": "Colección eliminada",
    "Tem certeza que deseja excluir esta coleção?":
      "¿Estás seguro de que deseas eliminar esta colección de forma permanente?",
    "Excluir coleção": "Eliminar colección",
    "Minhas coleções": "Mis colecciones",
    Criar: "Crear",
    Cancelar: "Cancelar",
    "Nome da coleção *": "Nombre de la colección *",
    "nova coleção": "nueva colección",
    "Exportar pdf": "Exportar pdf",
    "Algo deu errado": "Algo salió mal",
    "Tente novamente em instantes. Se o problema persistir, por favor, informe o suporte.":
      "Inténtelo de nuevo en un momento. Si el problema persiste, notifique al servicio de asistencia.",
    Mais: "Más",
    "Nenhum produto da mesma linha encontrado":
      "No se encontraron productos de la misma línea",
    "Por favor, digite alguma coisa.": "Escriba algo.",
    Fechar: "Cerca",
    Próxima: "Próxima",
    Anterior: "Anterior",
    Aceito: "Acepto",
    "Não aceito": "No acepto",
    "Leia os detalhes dos termos": "Lea los detalles de los términos",
    Cadastro: "Inscrever-se",
    Novidades: "Novedades",
    Mercados: "Mercados",
    "Mercado:": "Mercado:",
    "Ver todas as novidades": "Ver todas las novedades",
    "FILTRO TÉCNICO": "FILTRO TÉCNICO",
    "Saiba mais": "sepa mas",
    IDIOMAS: "IDIOMAS",
    "TERMOS DE USO": "TÉRMINOS DE USO",
    SAIR: "CERRAR SESIÓN",
    Calculadora: "Calculadora",
    "Calcule o metro linear": "Calcular el metro lineal",
    "Largura (m):": "Anchura (m):",
    "Comprimento (m):": "Longitud (m):",
    ou: "o",
    "Preencha o nº de m²:": "Complete los m²",
    "Número em metros lineares": "número en metros lineales",
    Busca: "Busca",
    Buscar: "Buscar",
    "Busque por código, nome, linha, etc.":
      "Busque por código, nombre, línea, etc.",
    "Filtro Técnico": "Filtro técnico",
    "Linha ou Coleção": "Línea o Colección",
    Cores: "Colores",
    "Tipo de Aplicação": "Tipo de aplicacion",
    Aplicar: "Aplicar",
    "Remoção dos favoritos": "Eliminar favoritos",
    "Tem certeza que deseja remover este produto dos favoritos?":
      "¿Estás segura de que quieres eliminarlo de tus favoritas?",
    Remover: "Eliminar",
    COLEÇÕES: "COLECCIONES",
    Coleções: "Colecciones",
    "Encerramento:": "Finalización:",
    Produtos: "Productos",
    "Meus Favoritos": "Favoritos",
    "Meus Dados": "Perfil",
    "Nome *": "Nombre *",
    Telefone: "Número de teléfono",
    Empresa: "Empresa",
    "Atualizar Dados": "Actualizar información",
    "Por favor, informe o seu nome.": "Por favor, informe su nombre.",
    "Por favor, informe um e-mail válido.":
      "Por favor, informe un e-mail válido.",
    "Dados atualizados com sucesso!": "Datos actualizados con éxito!",
    "Tire uma foto para o aplicativo analisar as cores e descobrir quais os produtos que melhor combinam com o seu ambiente.":
      "Tome una foto para que la aplicación analice los colores y descubra qué productos se adaptan mejor a su entorno.",
    "Paleta de cores": "Paleta de color",
    "Clique em uma cor para filtrar os produtos":
      "Elija un color para filtrar los productos",
    "Produtos referentes": "Productos de referencia",
    "Já sou cadastrado": "Ya estoy registrado",
    Senha: "Contraseña",
    "Senha *": "Contraseña *",
    "Por favor, informe uma senha com pelo menos 6 caracteres.":
      "Ingrese una contraseña con al menos 6 caracteres.",
    "A senha e a verificação de senha estão diferentes.":
      "La contraseña y la verificación de la contraseña son diferentes.",
    Entrar: "Iniciar sesión",
    "Ainda não é cadastrado?": "Todavía no estas registrado?",
    Cadastrar: "inscrever-se",
    "Entrar como visitante": "Iniciar sesión como invitado",
    'Para ter acesso a 100% das funcionalidades do catálogo como "favoritos", "PDFs personalizados" é necessário estar cadastrado.':
      'Para tener acceso al 100% de las funciones del catálogo, como "favoritos", "PDFs personalizados", debe estar registrado.',
    Linha: "Línea",
    VOLTAR: "VOLVER",
    "Recuperação de senha": "Recuperación de contraseña",
    "Informe o seu e-mail *": "Informar a su correo electrónico *",
    Avançar: "Próximo",
    "Código *": "Código *",
    "Nova senha *": "Nueva contraseña *",
    "Confirmar senha *": "Confirmar contraseña *",
    "Senha alterada com sucesso.": "Contraseña cambiada con éxito.",
    "Faça login": "Iniciar sesión",
    "agora mesmo.": "ahora mismo",
    "Trocar senha": "Cambia la contraseña",
    "Usuário não encontrado em nosso sistema.":
      "Usuario no encontrado en nuestro sistema.",
    "Parece que o código informado está incorreto. Verifique e tente novamente.":
      "Parece que el código ingresado es incorrecto. Compruébalo y vuelve a intentarlo.",
    "Por favor, informe sua nova senha": "Ingrese su nueva contraseña",
    "Por favor, confirme a sua nova senha": "Confirme su nueva contraseña",
    "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente":
      "Su contraseña y la confirmación de la contraseña son diferentes. Por favor revisa e intenta de nuevo",
    "Proteção de dados": "Protección de Datos",
  },
};
