<template>
  <div class="navbar desktop-hidden">
    <div class="container">
      <font-awesome-icon
        :icon="['fas', 'home']"
        :class="{ active: $route.name == 'Home' }"
        @click="$navigate('/home')"
      />
      <font-awesome-icon
        :icon="['fas', 'layer-group']"
        :class="{
          active:
            $route.name == 'Collection' || $route.name == 'CollectionIntern',
        }"
        @click="$navigate('/colecoes')"
      />
      <font-awesome-icon
        :icon="['fas', 'camera']"
        class="desktop-hidden"
        :class="{ active: $route.name == 'Colortone' }"
        @click="$navigate('/colortone')"
      />
      <font-awesome-icon
        v-show="userLoggedIn"
        :icon="['far', 'heart']"
        :class="{ active: $route.name == 'UserCollections' }"
        @click="$navigate('/minhas-colecoes')"
      />
      <font-awesome-icon
        v-show="userLoggedIn"
        :icon="['far', 'user']"
        :class="{ active: $route.name == 'UserProfile' }"
        @click="$navigate('/meus-dados')"
      />
    </div>
  </div>
</template>

<script>
import authentication from "../util/authentication";
export default {
  name: "Navbar",
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showMenuIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
  },
};
</script>
<style>
.navbar {
  position: fixed;
  width: 100vw;
  height: 50px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.22);
  z-index: 9999;
}

.navbar .container {
  position: relative;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .container svg {
  font-size: 1.3em;
  color: #888;
  cursor: pointer;
  transition: color 0.25s ease;
}

.navbar .container svg.active {
  color: var(--primary);
}
</style>
