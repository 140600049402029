<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ t("Novidades") }}
        </h1>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div v-show="!loading" class="products-holder">
          <ProductItem
            v-for="prod in products"
            :key="prod.id"
            :id="prod.id"
            :image="prod.coverImage"
            :name="prod[currentLanguage]"
            :isFavorite="prod.isFavorite"
            @favorite="checkFavorites"
          />
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import favorites from "../util/favorites";

export default {
  name: "NewProducts",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      products: [],
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    checkFavorites() {
      let allFavorites = favorites.list();
      if (!allFavorites) return;
      this.products.map((fav) => {
        if (allFavorites.includes(fav.id)) {
          fav.isFavorite = true;
        } else {
          fav.isFavorite = false;
        }
      });
    },
    getProducts() {
      http
        .get(
          `product/list?orderBy=timestamp&take=30`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = response.data.list.map((p) => {
              p.isFavorite = false;
              return p;
            });
            this.checkFavorites();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
