<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ keyword }}
        </h1>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div v-show="!loading" class="products-holder">
          <ProductItem
            v-for="prod in products"
            :key="prod.id"
            :id="prod.id"
            :image="prod.coverImage"
            :name="prod[currentLanguage]"
            :isFavorite="prod.isFavorite"
            @favorite="checkFavorites"
          />
        </div>

        <button
          v-show="!allProductsLoaded && !loading"
          @click="loadMore"
          class="btn desktop4 tablet8 mobile12 mx-auto mt-2"
        >
          <span v-show="!loadingMore" v-translate>Mais</span>
          <div v-show="loadingMore" class="loading-spinner"></div>
        </button>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import favorites from "../util/favorites";

export default {
  name: "Search",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      keyword: this.$route.params.keyword.replaceAll("-", " "),
      products: [],
      loadingMore: false,
      page: 1,
      take: 30,
      allProductsLoaded: false,
    };
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    checkFavorites() {
      let allFavorites = favorites.list();
      if (!allFavorites) return;
      this.products.map((fav) => {
        if (allFavorites.includes(fav.id)) {
          fav.isFavorite = true;
        } else {
          fav.isFavorite = false;
        }
      });
    },
    loadMore() {
      this.page++;
      this.loadingMore = true;
      this.getProducts();
    },
    getProducts() {
      http
        .get(
          `product/list?keyword=${this.keyword}&page=${this.page}&take=${this.take}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = [...this.products, ...response.data.list];
            if (this.page >= response.data.pages) {
              this.allProductsLoaded = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
  },
};
</script>
