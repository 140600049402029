<template>
  <div>
    <Header
      :showMenuIcon="false"
      :showSearchIcon="false"
      :showMenuLinks="false"
    />
    <div class="page-anim1 mt-header">
      <div class="container desktop4">
        <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
          {{ t("Cadastro") }}
        </h1>
        <div class="input-item page-anim2">
          <span v-translate>Nome *</span>
          <input type="text" v-model="form.name" />
        </div>
        <div class="input-item page-anim2">
          <span v-translate>Telefone</span>
          <the-mask
            v-model="form.phoneNumber"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="false"
          />
        </div>
        <div class="input-item page-anim2">
          <span v-translate>Empresa</span>
          <input type="text" v-model="form.company" />
        </div>
        <div class="input-item page-anim2">
          <span v-translate>E-mail *</span>
          <input type="email" v-model="form.email" />
        </div>
        <div class="input-item page-anim2">
          <span v-translate>Senha *</span>
          <input type="password" v-model="form.password" />
        </div>
        <div class="input-item page-anim2">
          <span v-translate>Confirmar senha *</span>
          <input type="password" v-model="form.password2" />
        </div>

        <transition enter-active-class="animated fadeIn">
          <p v-show="warningMessage != ''" class="warning red">
            {{ warningMessage }}
          </p>
        </transition>

        <button
          @click="checkForm"
          class="btn desktop12 tablet12 mobile12 mx-auto page-anim3 mb-2"
        >
          <span v-translate>Cadastrar</span>
        </button>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showTerms" class="terms-holder">
        <div class="container">
          <div class="title" v-translate>Proteção de dados</div>
          <p>{{ terms.paragraph1[currentLanguage] }}</p>
          <p>{{ terms.paragraph2[currentLanguage] }}</p>
          <a href="" v-translate class="mt-2">Leia os detalhes dos termos</a>

          <div class="flex-between mobile12 tablet8 desktop6">
            <button @click="showTerms = false" class="btn bg-red">
              <span v-translate>Não aceito</span>
            </button>
            <button @click="signUp" class="btn">
              <span v-show="!loading" v-translate>Aceito</span>
              <div v-show="loading" class="loading-spinner"></div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";
import authentication from "../util/authentication";
import Header from "../components/Header";

export default {
  name: "Cadastro",
  components: {
    Header,
  },
  data() {
    return {
      warningMessage: "",
      loading: false,
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        company: "",
        password: "",
      },
      showTerms: false,
      terms: {
        paragraph1: {
          pt:
            "Este aplicativo usa cookies para melhorar a experiência do usuário. Ao utilizar nosso aplicativo, você consente com todos os cookies de acordo com nossa Política de Cookies.",
          en:
            "This application uses cookies to improve the user experience. By using our app, you consent to all cookies in accordance with our Cookie Policy.",
          es:
            "Esta aplicación utiliza cookies para mejorar la experiencia del usuario. Al utilizar nuestra aplicación, acepta todas las cookies de acuerdo con nuestra Política de cookies.",
        },
        paragraph2: {
          pt:
            "Seus dados serão armazenados e criptografados pela Cipatex, e não serão divlgados e/ou utilizados para outros fins, apenas para a sua utilização dentro do aplicativo.",
          en:
            "Your data will be stored and encrypted by Cipatex, and will not be shared and / or used for other purposes, only for use within the application.",
          es:
            "Sus datos serán almacenados y encriptados por Cipatex, y no serán compartidos y / o usados ​​para otros propósitos, solo para uso dentro de la aplicación.",
        },
      },
    };
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "es";
      } else if (localStorage.language == "en") {
        return "en";
      }
      return "pt";
    },
  },
  methods: {
    checkForm() {
      if (this.form.name.length < 4) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe o seu nome."
        ];
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe um e-mail válido."
        ];
        return;
      }
      if (this.form.password.length < 6) {
        this.warningMessage = this.$translate.locale[
          "Por favor, informe uma senha com pelo menos 6 caracteres."
        ];
        return;
      }
      if (this.form.password2 !== this.form.password) {
        this.warningMessage = this.$translate.locale[
          "A senha e a verificação de senha estão diferentes."
        ];
        return;
      }

      this.warningMessage = false;
      this.showTerms = true;
    },
    signUp() {
      if (!this.loading) {
        this.warningMessage = "";
        this.loading = true;
        this.addObj();
      }
    },
    addObj() {
      http
        .post("user", this.form, authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.terms-holder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  z-index: 9999;
}

.terms-holder .container {
  position: relative;
  width: 72%;
  max-width: 520px;
  min-width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.terms-holder .title {
  font-size: 1.6em;
  color: #fff;
}

.terms-holder p {
  color: #fff;
  text-align: justify;
  margin-top: 35px;
  letter-spacing: 1px;
}
</style>
