<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="opened" @click="closeIt" class="bg-cover"></div>
    </transition>

    <transition
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
    >
      <div v-show="opened" class="holder">
        <font-awesome-icon
          @click="closeIt"
          class="close pointer"
          :icon="['fas', 'times']"
        />
        <div class="header-holder">
          <font-awesome-icon :icon="['fas', 'search']" />
          <p v-translate>Busca</p>
        </div>
        <div class="input-holder">
          <input type="text" v-model="keyword" @keypress.enter="search" />
          <span v-translate>Busque por código, nome, linha, etc.</span>
        </div>

        <transition enter-active-class="animated fadeIn">
          <p v-show="warningMessage != ''" class="warning red">
            {{ warningMessage }}
          </p>
        </transition>

        <button class="app-btn desktop12 tablet12 mobile12" @click="search">
          <span v-translate>Buscar</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: "",
      warningMessage: "",
    };
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
  },
  created() {},
  methods: {
    search() {
      if (!this.keyword) {
        this.warningMessage = this.$translate.locale[
          "Por favor, digite alguma coisa."
        ];
        return;
      }
      this.warningMessage = "";
      this.$navigate(`/busca/${this.stringToSlug(this.keyword)}`);
      // window.location = ;
    },
    closeIt() {
      this.$emit("closeSearch");
    },
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaeeeeiiiioooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
  },
};
</script>
<style scoped>
.holder {
  position: fixed;
  width: 100vw;
  max-width: 480px;
  padding: 25px 20px 25px 20px;
  margin: 0 auto 0 auto;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 6 0 0 6px;
  background-color: #fff;
  z-index: 10000;
}

.holder .close {
  position: absolute;
  font-size: 2.2em;
  color: var(--secondary);
  top: 25px;
  right: 25px;
  z-index: 2;
}

.header-holder {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.header-holder svg {
  font-size: 1.6em;
  color: var(--primary);
}

.header-holder p {
  font-family: fontBold;
  font-size: 1.8em;
  color: var(--primary);
  margin-left: 15px;
}

.input-holder {
  position: relative;
  width: 100%;
  margin-top: 15px;
}

.input-holder input {
  position: relative;
  display: block;
  font-family: fontRegular;
  padding-left: 12px;
  font-size: 1.3em;
  width: 100%;
  height: 50px;
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.input-holder span {
  position: relative;
  display: block;
  margin: 5px 0 0 8px;
}

@media screen and (min-width: 1160px) {
}
</style>
