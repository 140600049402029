<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showComponent"
        @click="closeComponent"
        class="screen-loading"
      ></div>
    </transition>
    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <div v-show="showComponent" class="opt-holder">
        <div v-show="loading" class="flex-center">
          <div class="loading2 my-2"></div>
        </div>
        <ul :class="{ saving: savingProduct }" v-show="!loading">
          <li class="title">
            <p v-translate>Salvar produto em:</p>
            <img :src="image" alt="imagem do produto" />
          </li>
          <li
            @click="addProductInCollection(list.id)"
            v-for="list in userLists"
            :key="list.id"
            class="pointer"
          >
            <font-awesome-icon :icon="['fas', 'plus']" />
            <p>{{ list.name }}</p>
            <span>{{ `(${list.quantity})` }}</span>
          </li>
          <li
            @click="showNewCollection = true"
            class="new pointer"
            v-show="!showNewCollection"
          >
            <font-awesome-icon :icon="['fas', 'plus-circle']" />
            <p v-translate>nova coleção</p>
          </li>
          <div v-show="showNewCollection">
            <div class="input-item">
              <span v-translate>Nome da nova coleção *</span>
              <input
                @keypress.enter="createCollection"
                type="text"
                v-model="newCollectionName"
              />
            </div>
            <div class="flex-between">
              <div @click="showNewCollection = false" class="app-btn border">
                <span v-translate>Cancelar</span>
              </div>
              <div @click="createCollection" class="app-btn">
                <div v-show="creatingCollection" class="loading2 white"></div>
                <span v-show="!creatingCollection" v-translate>Criar</span>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "@/util/authentication";
export default {
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      userLists: [],
      showNewCollection: false,
      newCollectionName: "",
      creatingCollection: false,
      savingProduct: false,
    };
  },
  methods: {
    createCollection() {
      if (!this.newCollectionName) return;
      this.creatingCollection = true;

      let newList = { name: this.newCollectionName };

      http
        .post("/userList", newList, authentication.getHeaders())
        .then((response) => {
          this.userLists.unshift(response.data);
          this.$toast({ text: this.$translate.locale["Coleção criada"] });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showNewCollection = false;
        });
    },
    addProductInCollection(listId) {
      if (this.savingProduct) return;

      this.savingProduct = true;
      const objToSend = { userListId: listId, productId: this.id };
      http
        .post("userList/add/product", objToSend, authentication.getHeaders())
        .then(() => {
          this.$toast({ text: this.$translate.locale["Produto adicionado"] });
          let list = this.userLists.find((list) => list.id === listId);
          list.quantity += 1;
        })
        .catch((err) => {
          this.$toast({ type: "error", text: err.response.data });
        })
        .finally(() => {
          this.savingProduct = false;
        });
    },
    closeComponent() {
      this.loading = false;
      this.savingProduct = false;
      this.$emit("closeComponent");
    },
    getUserLists() {
      this.loading = true;
      http
        .get("userList/list", authentication.getHeaders())
        .then((response) => {
          this.userLists = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    showComponent: {
      handler: function(val) {
        if (val) this.getUserLists();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.opt-holder {
  position: fixed;
  width: 100vw;
  max-width: 540px;
  padding: 1.2em 2em 0.6em 2em;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 1.5em 1.5em 0 0;
  z-index: 99999;
  ul {
    position: relative;
    li {
      position: relative;
      width: 100%;
      padding: 1.2em 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #eee;
      svg {
        color: var(--primary);
      }
      p {
        font-size: 1.15em;
        color: var(--primary);
        margin-left: 12px;
      }
      span {
        color: #999;
        margin-left: auto;
      }
      &.title {
        p {
          font-size: 1.2em;
          color: #999;
          margin-left: 0;
        }
        img {
          position: relative;
          max-width: 48px;
          max-height: 42px;
          margin-left: auto;
          border-radius: 3px;
        }
      }
      &.new {
        justify-content: center;
        border-bottom: none;
        padding-top: 1.5em;
        p {
          margin-left: 12px;
          color: var(--dark-primary);
          font-size: 1.2em;
        }
        svg {
          font-size: 1.4em;
          color: var(--dark-primary);
        }
      }
    }
  }
}

ul.saving {
  opacity: 0.5;
}
</style>
