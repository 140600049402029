import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import "./assets/css/desktop.css";
import "./assets/css/tablet.css";
import "./assets/css/mobile.css";
import "./assets/css/animate.css";

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

Vue.use(require("vue-moment"));
import VueSweetalert2 from "vue-sweetalert2";
const options = {
  confirmButtonColor: "#0f2651",
  cancelButtonColor: "#aaa",
};
Vue.use(VueSweetalert2, options);
import "sweetalert2/dist/sweetalert2.min.css";

import "swiper/swiper-bundle.css";

import VueTranslate from "vue-translate-plugin";
Vue.use(VueTranslate);

import PinchZoom from "vue-pinch-zoom";
Vue.component("pinch-zoom", PinchZoom);

import { library } from "@fortawesome/fontawesome-svg-core";
import {} from "@fortawesome/fontawesome-svg-core";
import {
  faBookmark,
  faFilePdf,
  faHeart,
  faShareSquare,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBookmark as faBookmarkFilled,
  faCalculator,
  faCamera,
  faChevronLeft,
  faChevronRight,
  faHome,
  faLayerGroup,
  faPlus,
  faSearch,
  faTimes,
  faHeart as faHeartFilled,
  faExpand,
  faMinusCircle,
  faFileDownload,
  faPlusCircle,
  faEdit,
  faTrash,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronLeft,
  faChevronRight,
  faHome,
  faLayerGroup,
  faCamera,
  faHeart,
  faUser,
  faPlus,
  faSearch,
  faTimes,
  faCalculator,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faHeart,
  faHeartFilled,
  faShareSquare,
  faExpand,
  faMinusCircle,
  faFileDownload,
  faFilePdf,
  faBookmark,
  faPlusCircle,
  faEdit,
  faTrash,
  faLongArrowAltRight,
  faBookmarkFilled
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.prototype.$toast = function(options) {
  let { type, text } = options;
  if (!type) type = "success";
  if (!text) text = "";
  this.$swal({
    toast: true,
    type: type,
    title: text,
    position: "top-right",
    showConfirmButton: false,
    timer: 3200,
  });
};

(Vue.prototype.$navigate = function(route) {
  if (!route) route = "/home";

  router.push({
    path: route,
    query: { lang: localStorage.language ? localStorage.language : "br" },
  });
}),
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
