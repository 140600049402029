<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <h1
          class="page-title page-anim2 mb-1 desktop-hidden"
          style="margin-bottom:10px;"
        >
          {{ subcategory[currentLanguage] }}
        </h1>
        <div class="section-image-holder desktop-hidden">
          <div class="bg-holder">
            <img :src="subcategory.image" :alt="subcategory[currentLanguage]" />
          </div>
          <div class="front-image-holder">
            <img
              :src="subcategory.parent.image"
              :alt="subcategory[currentLanguage]"
            />
          </div>
        </div>

        <div
          class="page-title-image tablet-hidden mobile-hidden"
          :style="`background-image:url(${subcategory.image})`"
        >
          <div class="front-image-holder">
            <img
              class="front"
              :src="subcategory.parent.image"
              :alt="subcategory[currentLanguage]"
            />
          </div>
          <h1 class="page-title">{{ subcategory[currentLanguage] }}</h1>
        </div>

        <div
          @click="exportPdf"
          class="pdf-button mt-1-desktop mb-2-mobile mobile12"
        >
          <span v-translate>Exportar pdf</span>
          <font-awesome-icon :icon="['far', 'file-pdf']" />
        </div>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>

        <div v-show="!loading" class="products-holder mt-2-desktop">
          <ProductItem
            v-for="prod in products"
            :key="prod.id"
            :id="prod.id"
            :code="prod.code"
            :image="prod.coverImage"
            :name="prod[currentLanguage]"
          />
        </div>
      </div>
    </div>

    <PdfProductsExport
      :title="subcategory[currentLanguage]"
      :categoryImage="subcategory.parent.image"
      :products="products"
      :startPdfExport="startPdfExport"
      @exported="endPdfExport"
    />

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import PdfProductsExport from "@/components/PdfProductsExport";

export default {
  name: "Subcategory",
  components: {
    Header,
    Navbar,
    ProductItem,
    PdfProductsExport,
  },
  data() {
    return {
      warningMessage: "",
      loading: false,
      subcategory: {
        image: "",
        parent: { image: "" },
      },
      products: [],
      startPdfExport: false,
    };
  },
  created() {
    this.getSubCategory();
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  methods: {
    exportPdf() {
      this.startPdfExport = true;
    },
    endPdfExport() {
      this.startPdfExport = false;
    },
    getSubCategory() {
      http
        .get(
          `category?id=${this.$route.params.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.subcategory = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

      http
        .get(
          `product/list?categoryId=${this.$route.params.id}&take=-1`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.products = response.data.list.map((p) => {
              p.isFavorite = false;
              return p;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.section-image-holder {
  position: relative;
  display: block;
  width: 100%;
  height: 140px;
  margin: 0 auto 25px auto;

  .bg-holder {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto 25px auto;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;

    img {
      position: absolute;
      max-width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);
    }
  }

  .front-image-holder {
    position: absolute;
    right: 1.4em;
    top: -1.2em;
    width: 130px;
    height: 125px;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: relative;
      max-width: 90%;
      display: block;
      margin: 0 auto;
    }
  }
}
.text-holder {
  position: absolute;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  margin: 0 0 0 20px;
  p {
    font-size: 1.2em;
    color: var(--blue);
  }
}

.page-title-image {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 340px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  img.bg {
    max-height: 100%;
    border-radius: 8px;
  }
  img.front {
    position: absolute;
    max-height: 65%;
    border-radius: 8px;
    right: 120px;
    bottom: -40px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .page-title {
    position: absolute;
    width: 100%;
    padding: 60px 0 25px 35px;
    font-size: 3.2em;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(0deg, #000000dd, transparent);
    color: #fff;
  }
}

.pdf-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  width: 140px;
  height: 42px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  cursor: pointer;
  span {
    margin-right: 15px;
    font-size: 1.2em;
    color: var(--primary);
  }
  svg {
    font-size: 1.3em;
    color: var(--primary);
  }
}
</style>
