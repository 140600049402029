<template>
  <div class="page">
    <Header :showBackButton="true" :showLogo="false" />
    <div class="page-anim1 mt-header">
      <div class="container">
        <div v-show="collection.id" class="collection-holder">
          <div class="content">
            <span v-translate>COLEÇÕES</span>
            <h1 class="page-title">{{ collection[currentLanguage] }}</h1>
            <p>
              <b v-translate>Encerramento:</b>
              {{ collection.endDate | moment("DD/MM/YYYY") }}
            </p>
            <p><b v-translate>Mercado:</b> {{ collection.categories }}</p>
            <a
              class="link-hotsite"
              v-show="collection.hotsite"
              :href="collection.hotsite"
              rel="noreferrer"
              target="_blank"
            >
              <b v-translate>Saiba mais</b>
            </a>
          </div>
          <div class="img">
            <img :src="collection.image" alt="imagem da coleção" />
          </div>
        </div>

        <div
          v-show="products.length > 0"
          class="desktop12 tablet12 mobile12 mt-2"
        >
          <h1 class="page-title page-anim2 mb-1" style="margin-bottom:10px;">
            {{ t("Produtos") }}
          </h1>

          <div class="products-holder">
            <ProductItem
              v-for="prod in products"
              :key="prod.id"
              :id="prod.id"
              :image="prod.coverImage"
              :name="prod[currentLanguage]"
              :isFavorite="prod.isFavorite"
              @favorite="checkFavorites"
            />
          </div>
        </div>

        <div v-show="loading" class="flex-center mt-3">
          <div class="loading1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <Navbar />
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import favorites from "../util/favorites";

export default {
  name: "CollectionIntern",
  components: {
    Header,
    Navbar,
    ProductItem,
  },
  data() {
    return {
      warningMessage: "",
      loading: true,
      collection: {},
      products: [],
    };
  },
  computed: {
    currentLanguage() {
      if (localStorage.language == "es") {
        return "nameEs";
      } else if (localStorage.language == "en") {
        return "nameEn";
      }
      return "namePt";
    },
  },
  created() {
    http
      .get(`collection?id=${this.$route.params.id}`)
      .then((response) => {
        this.collection = response.data;
        this.products = this.collection.products.map((p) => {
          p.isFavorite = false;
          return p;
        });
        this.checkFavorites();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    checkFavorites() {
      let allFavorites = favorites.list();
      if (!allFavorites) return;
      this.products.map((fav) => {
        if (allFavorites.includes(fav.id)) {
          fav.isFavorite = true;
        } else {
          fav.isFavorite = false;
        }
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.collection-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    position: relative;
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
      color: var(--primary);
    }
  }

  .img {
    position: relative;
    width: 35%;

    img {
      position: relative;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .link-hotsite b {
    position: relative;
    color: #1770c4;
  }
}

@media screen and (min-width: 1160px) {
  .collection-holder {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
</style>
